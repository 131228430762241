export enum HeaderUser {
  None,
  Discover,
  Network,
  Zones,
}

export enum HeaderUserLink {
  ZonesMap,
  ZonesAll,
  BookmarkUsers,
  Chats,
  ZonesMy,
  BookmarkZones,
}

// export type HeaderUserLink = HeaderUserLink | HeaderUserNetwork | HeaderUserZones;

export interface HeaderUserMenu {
  menu: HeaderUser,
  link?: HeaderUserLink,
}

