import { IZAuthSocialParent, IZAuthUser } from './auth.model';
import { InfoZone, InfoZoneFactory } from './info-zone.model';
import { IZZone } from './zone.model';

export interface IZNotifi extends InfoZone {
    ntype: 1 | 2 | 11 | 12;
    status: number;

    message?: string;
    reason?: string;

    zone?: IZZone;
    refZone?: string;

    user?: IZAuthSocialParent;
    refUser?: string;

    // refReporterCustomer?: string;
    // reporterCustomer?: IZCustomer;
    refCustomer?: string;

    refReporterUser?: string;
    reporterUser?: IZAuthUser;

    changeField?: string;
    changeWhat?: string;
    changeOld?: string;
    changeNew?: string;
}

// export function notifiNew(uid: string, createdBy: string): IZNotifi {
//     return {
//         uid,
//         createdBy,
//         status: 5,
//         ntype: 1,
//         refZone: '',
//         refUser: '',
//         // refReporterCustomer: '',
//         refReporterUser: '',
//         changeField: '',
//         changeWhat: '',
//         // refNotifier: createdBy,
//     };
// }

// export function notifiMapTo(o: IZNotifi): IZNotifi {
//     delete o.user;
//     delete o.zone;
//     delete o.reporterUser;
//     delete o.type;
//     return o;
// }


export class NotifiFactory extends InfoZoneFactory {
    static create(o: Partial<IZNotifi>): IZNotifi {
        return {
            uid: o.uid,
            createdBy: o.createdBy,
            status: 5,
            ntype: 1,
            refZone: '',
            refUser: '',
            refReporterUser: '',
            changeField: '',
            changeWhat: '',
        };
    }

    static mapTo(o: IZNotifi): void {
        super.mapTo(o);
        delete o.user;
        delete o.zone;
        delete o.reporterUser;
    }
}