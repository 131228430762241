<div
  [@onFade]="onFadeSate"
  fxLayout="row wrap"
  fxLayoutAlign="center center"
  [ngClass]="auth.userAny ? 'iz-header':'iz-header-small'"
>

  <div
    fxFlex="grow"
    fxLayoutAlign="center center"
    class="w-100"
  >
    <div
      *ngIf="auth.userAny && (data.headerUser$|async).menu "
      fxLayout="row"
      fxLayoutAlign="space-evenly center"
      class="w-100"
    >
      <ng-container [ngSwitch]="(data.headerUser$|async).menu">

        <ng-container *ngSwitchCase="1">
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            fxFlex="33.3"
            class="iz-header-user-wrapper"
            [class.iz-header-user-wrapper-active]="(data.headerUser$|async)?.link === 1"
          >
            <button
              (click)="toogleUserMenu(1)"
              fxLayout="column"
              fxLayoutAlign="center center"
              class="iz-btn-empty iz-header-toggle iz-mr-1"
            >
              <mat-icon class="iz-icon iz-plr-1">list_alt</mat-icon>
            </button>
            <!-- <button
              (click)="toogleUserMenu(1)"
              class="iz-btn-empty iz-toggle-label iz-ml-1"
            >Liste</button> -->
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            fxFlex="33.3"
            class="iz-header-user-wrapper"
            [class.iz-header-user-wrapper-active]="(data.headerUser$|async)?.link === 0"
          >
            <button
              (click)="toogleUserMenu(0)"
              fxLayout="column"
              fxLayoutAlign="center center"
              class="iz-btn-empty iz-header-toggle iz-ml-1"
            >
              <mat-icon
                [svgIcon]="'iz-location'"
                class="iz-icon iz-plr-1"
              ></mat-icon>
            </button>
            <!-- <button
              (click)="toogleUserMenu(0)"
              class="iz-btn-empty iz-toggle-label iz-ml-1"
            >Karte</button> -->
          </div>

          <div fxFlex="33.1"></div>
        </ng-container>


        <ng-container *ngSwitchCase="2">
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            fxFlex="33.3"
            class="iz-header-user-wrapper"
            [class.iz-header-user-wrapper-active]="(data.headerUser$|async)?.link === 2"
          >
            <button
              (click)="toogleUserMenu(2)"
              fxLayout="column"
              fxLayoutAlign="center center"
              class="iz-btn-empty iz-header-toggle iz-ml-1"
            >
              <mat-icon class="iz-icon iz-plr-1">bookmark</mat-icon>
            </button>
            <!-- <div
              (click)="toogleUserMenu(2)"
              class="iz-toggle-label iz-ml-1"
            >Personen</div> -->
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            fxFlex="33.3"
            class="iz-header-user-wrapper"
            [class.iz-header-user-wrapper-active]="(data.headerUser$|async)?.link === 3"
          >
            <button
              (click)="toogleUserMenu(3)"
              fxLayout="column"
              fxLayoutAlign="center center"
              class="iz-btn-empty iz-header-toggle iz-mr-1"
            >
              <mat-icon
                class="iz-icon iz-plr-1"
                matBadgePosition="after"
                [matBadge]="chatsMessagesCountNew < 10 ? chatsMessagesCountNew : '+9'"
              >
                forum
              </mat-icon>
            </button>
            <!-- <div
              (click)="toogleUserMenu(3)"
              class="iz-toggle-label iz-ml-1"
            >Chat</div> -->
          </div>

          <div fxFlex="33.1"></div>
        </ng-container>
        <ng-container *ngSwitchCase="3">
          <div
            fxLayout="row"
            fxLayoutAlign="center center" 
            fxFlex="33.3"
            class="iz-header-user-wrapper"
            [class.iz-header-user-wrapper-active]="(data.headerUser$|async)?.link === 4"
          >
            <button
              (click)="toogleUserMenu(4)"
              fxLayout="column"
              fxLayoutAlign="center center"
              class="iz-btn-empty iz-header-toggle iz-ml-1"
            >
              <mat-icon class="iz-icon iz-plr-1">power_settings_new</mat-icon>
            </button>
            <!-- <div
              (click)="toogleUserMenu(4)"
              class="iz-toggle-label iz-ml-1"
            >Zonen</div> -->
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            fxFlex="33.3"
            class="iz-header-user-wrapper"
            [class.iz-header-user-wrapper-active]="(data.headerUser$|async)?.link === 5"
          >
            <button
              (click)="toogleUserMenu(5)"
              fxLayout="column"
              fxLayoutAlign="center center"
              class="iz-btn-empty iz-header-toggle iz-ml-1"
            >
              <mat-icon class="iz-icon iz-plr-1">bookmark</mat-icon>
            </button>
            <!-- <div
              (click)="toogleUserMenu(5)"
              class="iz-toggle-label iz-ml-1"
            >Merkliste</div> -->
          </div>

          <div fxFlex="33.1"></div>
        </ng-container>

      </ng-container>



    </div>


    <div
      *ngIf="(data.headerSearchShow$|async) && auth.userAny"
      class="iz-search-input"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <input
        #searchInput
        (keydown.enter)="headerSearchValueChangeKK(searchInput.value)"
        (input)="headerSearchValueChange()"
        [value]="data.headerSearchValueKK$ | async"
        (focus)="toogleFooter(0)"
        (blur)="toogleFooter(1)"
        class="input-field"
        type="text"
        placeholder="..."
      >
      <button
        (click)="headerSearchValueChangeKK(searchInput.value)"
        class="iz-btn-empty"
      >
        <mat-icon
          [class.icon-not-clicked]="!headerSearchValueClicked"
          [class.material-icons-outlined]="!(data.headerSearchValueKK$ | async)"
          class="icon"
        >filter_alt</mat-icon>
      </button>
    </div>
  </div>

</div>
