import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { FirestoreService } from '../firestore.service';
import { combineLatest, Observable, of } from 'rxjs';
import { IZAuthSocialParent, IZAuthUser } from '../../model/auth.model';
import { take, map, switchMap } from 'rxjs/operators';
import { IZZone, IZZType, ZoneFactory } from '../../model/zone.model';
import { IZCustomer } from '../../model/customer.model';
import { Query, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { InfoZone } from '../../model/info-zone.model';
import { IZList } from '../../model/list.model';

import { HandshakeRequestFactory, IZHandshakeRequest } from '../../model/handshake-request';
import { izid } from '../toolz';
import { IZConfigConnection } from '../../model/config.model';
import { ChatFactory, IZChat, IZChatMessage } from 'src/app/model/chat.model';
import { IZFocus } from 'src/app/model/focus.model';
import { IZCategory } from 'src/app/model/category.model';
import { IZNotifi, NotifiFactory } from 'src/app/model/notifi.model';
import { IZKid } from 'src/app/model/kid.model';
import { IZBookmark } from 'src/app/model/bookmark.model';
import { IZTag } from 'src/app/model/tag';


@Injectable({
  providedIn: 'root'
})
export abstract class DaoService {


  constructor(
    protected auth: AuthenticationService,
    protected fb: FirestoreService,
  ) { }

  /**
   * dtToday
   */
  protected dtToday() {
    const dt = new Date();
    dt.setHours(0);
    dt.setMinutes(0);
    dt.setSeconds(0);
    dt.setMilliseconds(0);
    return dt;
  }

  /**
   * uid - Firebase generated UID
   */
  public uid(): string {
    return this.fb.uid();
  }

  /**
   * uid - chat uid
   */
  public uidChat(uid1: string, uid2: string): string {
    return this.fb.uidChat(uid1, uid2);
  }


  /**
   * byAdmin - admin uid
   */
  public byAdmin(): string {
    return this.auth.userAdmin.uid;
  }


  /**
   * lastFound$
   * @param path -
   * @param list -
   */
  protected lastFound$<T extends InfoZone>(lister: IZList<T>): Observable<T[]> {

    // console.log('lastFound$ - ', lister);

    lister.startBeforeId = lister?.startAfter?.id || null;

    if (lister.list?.length > 0) {
      lister.endPage = false;
    } else {
      lister.endPage = true;
    }

    if (lister?.startAfterId === lister?.startBeforeId) {
      lister.endColl = true;
      // lister.endPage = true;
    } else {
      lister.endColl = false;
      // lister.endPage = false;
    }

    if (!lister.list || lister.list.length === 0) {
      lister.startAfter = lister.startAfter || null;
      lister.startAfterId = lister.startAfterId || null;
      return of(lister.list);
    }
    const path = lister.path;
    const lastOne = lister.list[lister.list.length - 1];
    if (!lastOne) {
      // lister.startAfter = null;
      return of(lister.list);
    }
    // if (lastOne.pathp) {
    //   path = lastOne.pathp + '/' + path;
    // }
    return this.fb.docLast$(path, lastOne.uid)
      .pipe(
        map((doc: QueryDocumentSnapshot<any>) => {
          lister.startAfter = doc || null;
          lister.startAfterId = doc?.id || null;
          return lister.list;
        })
      );
  }



  /**
   * userAdminByEmail$
   * @param email -
   */
  // userAdminByEmail$(email: string): Observable<IZAuth> {
  //   return this.fb.colWithIds$(`admins`, (ref: any) => {
  //     return ref.where('email', '==', email).limit(1);
  //   })
  //     .pipe(
  //       take(1),
  //       map((users: IZAuth[]) => {
  //         console.log('dao - userAdminByEmail$ - ', users);
  //         if (users && users.length) {
  //           const userAdmin = users[0];
  //           if (userAdmin && userAdmin.roles && userAdmin.roles.includes('admin')) {
  //             return userAdmin;
  //           } else {
  //             return null;
  //           }
  //         } else {
  //           return null;
  //         }
  //       })
  //     );
  // }

  /*
    ------------------ADMIN-------------------
  */

  // NOTIFIS

  // /**
  //  *
  //  * @param notifi -
  //  */
  // async upsetNotifi$(notifi: IZNotifi): Promise<void> {
  //   return await this.fb.upsert(`notifis/${notifi.uid}`, notifi);
  // }

  /**
   *
   * @param notifi -
   * @param notifiKid -
   */
  async notifiUserCreate$(notifi: IZNotifi, notifiKid?: IZKid): Promise<void> {
    if (notifiKid) {
      await this.fb.upsert(`users/${this.auth.userSocialParent.uid}/zonesUsersNotifis/${notifiKid.uid}`, notifiKid);
    }
    NotifiFactory.mapTo(notifi);
    await this.fb.upsert(`notifis/${notifi.uid}`, notifi);
  }

  /**
   * notifiZoneCreate$
   * @param notifi -
   * @param notifiKid -
   */
  async notifiZoneCreate$(notifi: IZNotifi, notifiKid?: IZKid): Promise<void> {
    if (notifiKid) {
      await this.fb.upsert(`users/${this.auth.userSocialParent.uid}/zonesNotifis/${notifiKid.uid}`, notifiKid);
    }
    NotifiFactory.mapTo(notifi);
    await this.fb.upsert(`notifis/${notifi.uid}`, notifi);
  }

  /**
   * -
   * @param notifi -
   */
  async notifiUserDelete$(notifi: IZNotifi): Promise<void> {
    try {
      await this.fb.delete(`users/${notifi.refUser}/zonesUsersNotifis/${notifi.uid}`);
    } catch (error) {
      console.log(error);
    }
    await this.notifiDelete$(notifi);
  }

  /**
   * -
   * @param notifi -
   */
  async notifiZoneDelete$(notifi: IZNotifi): Promise<void> {
    try {
      await this.fb.delete(`users/${notifi.refUser}/zonesNotifis/${notifi.uid}`);
    } catch (error) {
      console.log(error);
    }
    await this.notifiDelete$(notifi);
  }

  /**
   * notifiDelete$
   * @param notifi -
   */
  async notifiDelete$(notifi: IZNotifi): Promise<void> {
    await this.fb.upsert(`notifis/${notifi.uid}`, { status: 99 });
  }

  /**
   *
   * @param notifi -
   * @param notifiKid -
   */
  async notifiUserReport$(notifi: IZNotifi): Promise<void> {
    NotifiFactory.mapTo(notifi);
    await this.fb.upsert(`notifis/${notifi.uid}`, notifi);
  }



  /**
   * zoneUIUsersCount$
   * @param zone -
   */
  zoneUIUsersCount$(zone: IZZone): Observable<number> {
    return this.fb.colWithIds$(
      `zones/${zone.uid}/users`)
      .pipe(
        map((kids: IZKid[]) => kids?.length ? kids.length : 0)
      );
  }





  /**
   * joinReporter$
   * @param izEntry -
   */
  protected joinReporter$<T extends IZNotifi>(izEntry: T): Observable<T> {
    if (!izEntry) {
      return of(null);
    }
    if (!izEntry.refReporterUser) {
      izEntry.reporterUser = null;
      return of(izEntry);
    }
    return this.auth.GetUserParentByUUIDFull$(izEntry.refReporterUser)
      .pipe(
        map((reporter: IZAuthUser) => {
          izEntry.reporterUser = reporter || null;
          return izEntry;
        })
      );
  }


  /**
   * joinUser$
   * @param izEntry -
   */
  protected joinUser$<T extends IZNotifi>(izEntry: T): Observable<T> {
    if (!izEntry) {
      return of(null);
    }
    if (!izEntry.refUser) {
      izEntry.user = null;
      return of(izEntry);
    }
    return this.auth.GetUserParentByUUIDFull$(izEntry.refUser)
      .pipe(
        take(1),
        map((user: IZAuthSocialParent) => {
          izEntry.user = user || null;
          return izEntry;
        })
      );
  }


  // HANDSHAKEREQUESTS

  /**
   * -
   * @param handshakeRequest -
   */
  async handshakeRequestCreate$(handshakeRequest: IZHandshakeRequest, handshakeRequestKid?: IZKid): Promise<void> {
    if (handshakeRequestKid) {
      await this.fb.upsert(`users/${this.auth.userSocialParent.uid}/zonesHandshakeRequests/${handshakeRequestKid.uid}`, handshakeRequestKid);
    }
    HandshakeRequestFactory.mapTo(handshakeRequest);
    await this.fb.upsert(`handshakeRequests/${handshakeRequest.uid}`, handshakeRequest);
  }

  /**
   * -
   * @param handshakeRequest -
   */
  async handshakeRequestDelete$(handshakeRequest: IZHandshakeRequest): Promise<void> {
    await this.fb.delete(`users/${handshakeRequest.refUser}/zonesHandshakeRequests/${handshakeRequest.refZone}`);
    await this.fb.upsert(`handshakeRequests/${handshakeRequest.uid}`, { status: 99 });
  }



  // PRIVATE GENERAL

  /**
   * zoneByUid$
   * @param uid -
   */
  public zoneByUid$(uid: string): Observable<IZZone> {
    if (uid === '-1') {
      return of(
        ZoneFactory.create({
          uid: izid(),
          createdBy: this.auth.userAdmin.uid
        })
      );
    } else {
      return this.fb.doc$$<IZZone>(`zones/${uid}`)
        .pipe(
          switchMap((zone: IZZone) => {
            return this.joinCustomer$(zone);
          }),
          switchMap((zone: IZZone) => {
            return this.joinCategory$(zone);
          }),
        );
    }
  }

  /**
   * joinCustomer$
   * @param izEntry -
   */
  protected joinCustomer$<T extends IZZone>(izEntry: T): Observable<T> {
    if (!izEntry) {
      return of(null);
    }
    if (!izEntry.refCustomer) {
      izEntry.customer = null;
      return of(izEntry);
    }
    return this.fb.doc$(`customers/${izEntry.refCustomer}`)
      .pipe(
        take(1),
        map((customer: IZCustomer) => {
          izEntry.customer = customer || null;
          return izEntry;
        })
      );
  }






  /**
   * joinCategory$
   * @param izEntry -
   */
  protected joinCategory$<T extends IZZone>(izEntry: T): Observable<T> {
    if (!izEntry) {
      return of(null);
    }
    if (!izEntry.refCategory) {
      izEntry.category = null;
      return of(izEntry);
    }
    return this.fb.doc$(`configs/keywords/categorys/${izEntry.refCategory}`)
      .pipe(
        take(1),
        switchMap((category: IZCategory) => {
          return this.joinFocus$(category);
        }),
        map((category: IZCategory) => {
          izEntry.category = category || null;
          return izEntry;
        })
      );
  }

  /**
   * joinBookmark$
   * @param izEntry -
   */
  protected joinBookmark$<T extends IZZone | IZAuthSocialParent>(izEntry: T, link: string): Observable<T> {
    if (!izEntry) {
      return of(null);
    }
    return this.fb.doc$(`users/${this.auth.userSocialParent.uid}/${link}Bookmarks/${izEntry.uid}`)
      .pipe(
        take(1),
        map((bookmark: IZBookmark) => {
          izEntry.bookmark = bookmark || null;
          return izEntry;
        })
      );
  }

  /**
   * joinFeed$
   * @param izEntry -
   */
  protected joinFeed$<T extends IZZone>(izEntry: T): Observable<T> {
    if (!izEntry) {
      return of(null);
    }
    let path = `users/${this.auth.userSocialParent.uid}/zonesFeeds/${izEntry.uid}`;
    return this.fb.doc$(path)
      .pipe(
        take(1),
        map((feed: IZKid) => {
          izEntry.feed = feed || null;
          return izEntry;
        })
      );
  }

  /**
   * joinHandshakeRequest$
   * @param izEntry -
   */
  protected joinHandshakeRequest$<T extends IZZone>(izEntry: T): Observable<T> {
    if (!izEntry) {
      return of(null);
    }
    return this.fb.doc$(`users/${this.auth.userSocialParent.uid}/zonesHandshakeRequests/${izEntry.uid}`)
      .pipe(
        take(1),
        map((handshakeRequest: IZKid) => {
          izEntry.handshakeRequest = handshakeRequest || null;
          return izEntry;
        })
      );
  }

  protected joinCheckinI$<T extends IZZone>(izEntry: T): Observable<T> {
    if (!izEntry) {
      return of(null);
    }
    let path = `users/${this.auth.userSocialParent.uid}/zones/${izEntry.uid}`;
    if (izEntry.ztype === IZZType.bluetooth) {
      path = `users/${this.auth.userSocialParent.uid}/beacons/${izEntry.uid}`;
    }
    return this.fb.doc$(path)
      .pipe(
        take(1),
        map((kid: IZKid) => {
          izEntry.checkinI = kid || null;
          return izEntry;
        })
      );
  }

  /**
   * joinHighlight$
   * @param izEntry -
   */
  protected joinHighlight$<T extends IZAuthSocialParent>(izEntry: T, link: string): Observable<T> {
    if (!izEntry) {
      return of(null);
    }
    return this.fb.doc$(`users/${this.auth.userSocialParent.uid}/${link}Highlights/${izEntry.uid}`)
      .pipe(
        take(1),
        map((highlight: IZKid) => {
          izEntry.highlight = highlight || null;
          return izEntry;
        })
      );
  }












  // CONFIGS

  /**
   * configConnection$
   */
  configConnection$(): Observable<IZConfigConnection> {
    return this.fb.doc$('configs/connection');
  }


  /**
   * configFocuss$
   */
  configFocuss$(): Observable<IZFocus[]> {
    return this.fb.colWithIds$(`configs/keywords/focuss`);
  }


  /**
   * configCatregorys$
   * @param sort -
   */
  configCatregorys$(sort?: boolean): Observable<IZCategory[]> {
    return this.fb.colWithIds$(`configs/keywords/categorys`,
      (ref: Query) => {
        if (sort) ref = ref.where('sort', '>', 0).orderBy('sort');
        return ref;
      })
      .pipe(
        switchMap((categorys: IZCategory[]) => {
          if (!categorys) {
            return of([]);
          }
          const categorysFocus$ = categorys.map((category: IZCategory) => {
            return this.joinFocus$(category);
          });
          return combineLatest([...categorysFocus$]);
        }),
      );
  }


  /**
   * configCategorysByTitle$
   * @param title -
   */
  configCategorysByTitle$(title: string): Observable<IZCategory> {
    return this.fb.colWithIds$(`configs/keywords/categorys`,
      (ref: Query) => {
        ref = ref.where('title', '==', title);
        return ref;
      })
      .pipe(
        take(1),
        map((categorys: IZCategory[]) => {
          if (categorys && categorys.length) {
            return categorys[0];
          } else {
            return null;
          }
        }),
      );
  }

  /**
  * configTags$
  */
  configTags$(query: string): Observable<IZTag[]> {
    return this.fb.colWithIds$$(`tags`,
      (ref: Query) => {
        return ref;
      })
      .pipe(
        map((tags: IZTag[]) => {
          return tags.filter(tag => tag.title?.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
        } )
      )
  }


  /**
   * joinFocus$
   * @param category -
   */
  private joinFocus$(category: IZCategory): Observable<IZCategory> {
    if (!category) {
      return of(null);
    }
    if (!category.refFocus) {
      category.focus = null;
      return of(category);
    }
    return this.fb.doc$(`configs/keywords/focuss/${category.refFocus}`)
      .pipe(
        take(1),
        map((focus: IZFocus) => {
          category.focus = focus || null;
          return category;
        })
      );
  }





  /**
   * chatMy$
   * @param uid -
   */
  chatMy$(uid: string, uidWith?: string): Observable<IZChat> {
    return this.fb.doc$(`chats/${uid}`)
      .pipe(
        take(1),
        switchMap((chat: IZChat) => {
          if (!chat) {
            return of(null);
          }
          if (!uidWith) {
            uidWith = chat.uidUser1 === this.auth.userSocialParent?.uid ? chat.uidUser2 : chat.uidUser1;
          }
          return this.auth.GetUserParentByUUIDFull$(uidWith)
            .pipe(
              take(1),
              map((user: IZAuthUser) => {
                chat.user = user;
                return chat;
              })
            );
        }),
      );
  }

  /**
   * chatNew$
   * @param chat -
   */
  async chatNew$(chat: IZChat): Promise<void> {
    ChatFactory.mapTo(chat);
    return await this.fb.upsert(`chats/${chat.uid}`, chat);
  }

  async chatMessageNew$(chat: IZChat, message: IZChatMessage): Promise<void> {
    return await this.fb.upsert(`chats/${chat.uid}/messages/${message.uid}`, message);
  }

}
