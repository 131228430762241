import { Injectable } from '@angular/core';
import { SliderMenuState } from '../model/slider-menu.model';

@Injectable({
  providedIn: 'root'
})
export class SliderMenuService {

  menuState = SliderMenuState.Right;

  zonesState = SliderMenuState.Left;
  zonePreviewState = SliderMenuState.Left;

  dRangeState = SliderMenuState.Left;
  quickFilterState = SliderMenuState.Left;
  filter4State = SliderMenuState.Left;
  filter5State = SliderMenuState.Left;

  public periodFromd: Date;
  public periodTilld: Date;

  public zmode: number;

  public categorys: string[];

  public keywords: string;

  public markerPositiionsShow = true;

  // constructor() { }


  leftAll() {
    this.toggleZones(SliderMenuState.Left);
    this.toggleZonePreview(SliderMenuState.Left);
    this.toggleDRange(SliderMenuState.Left);
    this.toggleQuickFilter(SliderMenuState.Left);
    this.toggleFilter4(SliderMenuState.Left);
    this.toggleFilter5(SliderMenuState.Left);
  }

  rightAll() {
    this.toggleZones(SliderMenuState.Right);
    this.toggleZonePreview(SliderMenuState.Right);
    this.toggleDRange(SliderMenuState.Right);
    this.toggleQuickFilter(SliderMenuState.Right);
    this.toggleFilter4(SliderMenuState.Right);
    this.toggleFilter5(SliderMenuState.Right);
  }


  toggle(state?: SliderMenuState) {
    if (state) {
      if (this.menuState !== state) {
        this.menuState = state;
      }
    } else {
      this.menuState = this.menuState === SliderMenuState.Right ? SliderMenuState.Left : SliderMenuState.Right;
    }

  }

  get icon() {
    if (this.menuState === SliderMenuState.Left) {
      return 'arrow_drop_up';
    } else {
      return 'arrow_drop_down';
    }
  }




  toggleZones(state?: SliderMenuState) {
    if (state) {
      this.zonesState = state;
    } else {
      this.zonesState = this.zonesState === SliderMenuState.Right ? SliderMenuState.Left : SliderMenuState.Right;
    }
  }


  toggleZonePreview(state?: SliderMenuState) {
    if (state) {
      this.zonePreviewState = state;
    } else {
      this.zonePreviewState = this.zonePreviewState === SliderMenuState.Right ? SliderMenuState.Left : SliderMenuState.Right;
    }
  }



  toggleQuickFilter(state?: SliderMenuState) {
    if (state) {
      this.quickFilterState = state;
    } else {
      this.quickFilterState = this.quickFilterState === SliderMenuState.Right ? SliderMenuState.Left : SliderMenuState.Right;
    }
  }


  toggleDRange(state?: SliderMenuState) {
    if (state) {
      this.dRangeState = state;
    } else {
      this.dRangeState = this.dRangeState === SliderMenuState.Right ? SliderMenuState.Left : SliderMenuState.Right;
    }
  }


  toggleFilter4(state?: SliderMenuState) {
    if (state) {
      this.filter4State = state;
    } else {
      this.filter4State = this.filter4State === SliderMenuState.Right ? SliderMenuState.Left : SliderMenuState.Right;
    }
  }


  toggleFilter5(state?: SliderMenuState) {
    if (state) {
      this.filter5State = state;
    } else {
      this.filter5State = this.filter5State === SliderMenuState.Right ? SliderMenuState.Left : SliderMenuState.Right;
    }
  }
}
