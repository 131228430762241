export const environment = {
  production: true,
  host: 'https://info.zone/',
  firebaseConfig: {
    apiKey: 'AIzaSyDvxSNfhXZuSBmh5t4KDI93VNRyOZoMUTo',
    authDomain: 'hier-256720.firebaseapp.com',
    databaseURL: 'https://hier-256720.firebaseio.com',
    projectId: 'hier-256720',
    storageBucket: 'hier-256720.appspot.com',
    messagingSenderId: '838171318223',
    appId: '1:838171318223:web:c41fdda58272d5e435a4ba'
  },
  facebook: {
    appId: '2377109355846265',
    xfbml: true,
    version: 'v6.0'
  },
  token: {
    url: 'https://europe-west1-hier-256720.cloudfunctions.net/',
    xing: 'xing',
    linkedin: 'linkedin',
    instagram: 'instagram'
  },
  xing: {
    consumer_key: '9d416c4dc378e9e7ad32',
    src: 'https://www.xing-share.com/plugins/login.js',
  },
  linkedin: {
    url: 'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86oxr7kyhvy964&redirect_uri=https%3A%2F%2Finfo.zone&state=hierzonelinkedin&scope=r_liteprofile%20r_emailaddress',
    urlProfile: 'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86oxr7kyhvy964&redirect_uri=https%3A%2F%2Finfo.zone%2Fuser%2Fprofile&state=hierzonelinkedin&scope=r_liteprofile%20r_emailaddress',
    redirectUriPath: 'https://info.zone',
    redirectUriPathProfile: 'https://info.zone/user/profile',
  },
  providers: {
    facebook: '',
    twitter: '',
    xing: '',
    linkedin: '',
    instagram: '',
  },
  user: {
    deleteMe: 'https://europe-west1-hier-256720.cloudfunctions.net/userDeleteMe',
    disconnectMe: 'https://europe-west1-hier-256720.cloudfunctions.net/userDisconnectMe',
    deleteChat: 'https://europe-west1-hier-256720.cloudfunctions.net/userDeleteChat',
  },
  customer: {
    deleteMe: 'https://europe-west1-hier-256720.cloudfunctions.net/customerDeleteMe',
    upset: 'https://europe-west1-hier-256720.cloudfunctions.net/customerUpset',
    disconnectUserFromZone: 'https://europe-west1-hier-256720.cloudfunctions.net/customerDisconnectUserFromZone',
  },
  admin: {
    key: 'ab7903e5baa169dea5e41dc16dd59be77195bc9e',
    disconnectAllFromZone: 'https://europe-west1-hier-256720.cloudfunctions.net/adminDisconnectAllFromZone',
    userDelete: 'https://europe-west1-hier-256720.cloudfunctions.net/adminUserDelete',
    customerDelete: 'https://europe-west1-hier-256720.cloudfunctions.net/adminCustomerDelete',
    deleteZoneHard: 'https://europe-west1-hier-256720.cloudfunctions.net/adminDeleteZoneHard',
  },
  zone: {
    checkinPassword: 'https://europe-west1-hier-256720.cloudfunctions.net/zoneCheckinPassword',
  },
  gtm: {
    id: 'GTM-PZ49G4C'
  },
  emailhost: '@info.zone',
  shop: {
    url: 'https://shop.info.zone/'
  },
  email: {
    customerCreated: {
      link: 'https://europe-west1-hier-256720.cloudfunctions.net/emailCustomerCreated'
    },
    customerPasswordReset: {
      link: 'https://europe-west1-hier-256720.cloudfunctions.net/emailCustomerPasswordReset'
    },
    zoneCreatedAssigned: {
      link: 'https://europe-west1-hier-256720.cloudfunctions.net/emailZoneCreatedAssigned'
    },
    zoneChangesReset: {
      link: 'https://europe-west1-hier-256720.cloudfunctions.net/emailZoneChangesReset'
    },
    zoneExtendVisibleTilld: {
      link: 'https://europe-west1-hier-256720.cloudfunctions.net/emailZoneExtendVisibleTilld'
    },
    zoneChangeCustomer: {
      link: 'https://europe-west1-hier-256720.cloudfunctions.net/emailZoneChangeCustomer'
    },
    zoneDeleteHard: {
      link: 'https://europe-west1-hier-256720.cloudfunctions.net/emailZoneDeleteHard'
    },
  },
  map: {
    apiUrl: '',
    key: '',
    vmsUrls: '',
    forwardReverseGeocoding: {
      url: 'https://forward-reverse-geocoding.p.rapidapi.com/v1/search?format=json&accept-language=de&q=',
      xRapidapiKey: 'e37313df99msh338e97369f9343fp163b4ajsnd74e518563a8',
      xRapidapiHost: 'forward-reverse-geocoding.p.rapidapi.com',
    },
    mapbox: {
      accessToken: 'pk.eyJ1Ijoicm9ib3Rpa3oiLCJhIjoiY2ttbmtwOHJpMHo4bzJvcGw1am9ubmppMyJ9.riRLKPiAFS6hmg7rCb0tDA',
    },
  },
  recaptcha: {
    siteKey: '6LeQZlQaAAAAAAwOL8VMALbb0rFXKoBp57wlDSZx'
  }
};
