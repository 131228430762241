import { IZAuthSocialParent } from './auth.model';
import { InfoZone, InfoZoneFactory } from './info-zone.model';
import { IZZone } from './zone.model';

export interface IZHandshakeRequest extends InfoZone {
    rtype: number;
    status: number;

    message?: string;
    reason?: string;

    zone?: IZZone;
    refZone?: string;
    ztypeNew?: number;

    user?: IZAuthSocialParent;
    refUser?: string;
}


export class HandshakeRequestFactory extends InfoZoneFactory {
    static create(o: Partial<IZHandshakeRequest>): IZHandshakeRequest {
        return {
            uid: o.uid,
            createdBy: o.createdBy,
            status: 5,
            rtype: 1,
            refZone: '',
            refUser: '',
            ztypeNew: 50,
        };
    }

    static mapTo(o: IZHandshakeRequest): void {
        super.mapTo(o);
        delete o.user;
        delete o.zone;
    }
}