import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { SharesService } from 'src/app/services/shares.service';
import { sbSuccessSett, sbWarnSett } from 'src/app/services/constz';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AuthLinkedInResp } from 'functions/src/models/auth.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { IZAuthSocialParent } from 'src/app/model/auth.model';
import { SnackBottomComponent } from '../dlg/snack-bottom/snack-bottom.component';

declare global {
  interface Window { onXingAuthLogin: any; }
}

@UntilDestroy()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('xingload') xingloadElementRef: ElementRef;

  public loading = true;
  public isChrome = true;
  isPwa: boolean;

  // public siteKey = environment.recaptcha.siteKey;
  private recaptchaValid: boolean;

  constructor(
    public router: Router,
    public auth: AuthenticationService,
    private sb: MatSnackBar,
    private spinner: NgxSpinnerService,
    private data: DataService,
    private shares: SharesService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.recaptchaV3Service
      .execute('importantAction')
      .pipe(untilDestroyed(this))
      .subscribe((token) => this.recaptchaResolved(token));

    this.isPwa = this.shares.isPwaApp();


    this.spinner.show('spContentLoad');
    console.log('environment.production - ', environment.production);
    this.auth.GetUserParent$()
      .pipe(take(1))
      .subscribe((u) => {
        console.log('HOME: ngOnInit - user', u);
        if (u) {
          this.router.navigate(['/user/my-list']);
        } else {
          this.spinner.hide('spContentLoad');
          this.loading = false;
          // check if we back from linkedin
          this.signLinkedinCallback();
        }
      },
        () => { this.loading = false; this.spinner.hide('spContentLoad'); }
      );

  }

  ngAfterViewInit() {
    // this.shares.loadXingScript(this.xingloadElementRef);
  }

  async SignFacebook() {
    if (!this.recaptchaValid) { return; }
    this.spinner.show('spContentLoad');
    console.log('before SignFacebook2');
    try {
      const userAuthSocialParent = await this.auth.loginFacebook();
      if (!userAuthSocialParent) {
        this.auth.SignOut();
        this.spinner.hide('spContentLoad');
        return;
      }

      this.auth.initUser();
      this.data.footerShowOn();
      console.log('after SignFacebook2');
      this.sb.openFromComponent(SnackBottomComponent, {
        data: `FACEBOOK: Sie wurden zum INFO.ZONE erfolgreich authentifiziert`,
        ...sbSuccessSett,
      });
      this.navigateAfterSignIn(userAuthSocialParent);
      this.spinner.hide('spContentLoad');
    } catch (error) {
      console.log(error);
      this.spinner.hide('spContentLoad');
      this.sb.openFromComponent(SnackBottomComponent, {
        data: `FACEBOOK-Fehler: ${error}`,
        ...sbWarnSett,
      });
      this.auth.SignOut();
    }
  }

  /**
   *
   */
  async SignTwitter() {
    if (!this.recaptchaValid) { return; }
    this.spinner.show('spContentLoad');
    console.log('before SignTwitter1');
    try {
      const userAuthSocialParent = await this.auth.loginTwitter();
      if (!userAuthSocialParent) {
        this.auth.SignOut();
        this.spinner.hide('spContentLoad');
        return;
      }

      this.auth.initUser();
      this.data.footerShowOn();
      console.log('after SignTwitter1');
      this.sb.openFromComponent(SnackBottomComponent, {
        data: `Twitter: Sie wurden zum INFO.ZONE erfolgreich authentifiziert`,
        ...sbSuccessSett,
      });
      this.navigateAfterSignIn(userAuthSocialParent);
      this.spinner.hide('spContentLoad');
    } catch (error) {
      console.log(error);
      this.spinner.hide('spContentLoad');
      this.sb.openFromComponent(SnackBottomComponent, {
        data: `Twitter-Fehler: ${error}`,
        ...sbWarnSett,
      });
      this.auth.SignOut();
    }

  }

  /**
   *
   */
  async signXing() {
    if (!this.recaptchaValid) { return; }
    this.spinner.show('spContentLoad');
    window.onXingAuthLogin = async (userXingObject: any) => {
      // let output: any;
      console.log(userXingObject);
      console.log('signXing userXingObject', userXingObject);
      if (userXingObject.user) {

        console.log('before SignXing1');
        try {
          const userAuthSocialParent = await this.auth.loginXing(userXingObject.user);
          if (!userAuthSocialParent) {
            this.auth.SignOut();
            this.spinner.hide('spContentLoad');
            return;
          }

          this.auth.initUser();
          this.data.footerShowOn();
          console.log('after SignXing1');
          this.sb.openFromComponent(SnackBottomComponent, {
            data: `Xing: Sie wurden zum INFO.ZONE erfolgreich authentifiziert`,
            ...sbSuccessSett,
          });
          this.navigateAfterSignIn(userAuthSocialParent);
          this.spinner.hide('spContentLoad');
        } catch (error) {
          console.log(error);
          this.spinner.hide('spContentLoad');
          this.sb.openFromComponent(SnackBottomComponent, {
            data: `Xing-Fehler: ${error}`,
            ...sbWarnSett,
          });
          this.auth.SignOut();
        }

      } else if (userXingObject.error) {
        console.log('Error: ' + userXingObject.error);
        this.spinner.hide('spContentLoad');
        this.sb.openFromComponent(SnackBottomComponent, {
          data: `Xing-Fehler: ${userXingObject.error}`,
          ...sbWarnSett,
        });
      }
    };

    try {
      // window.onXingAuthLogin();
      const iframe = this.xingloadElementRef.nativeElement.getElementsByTagName('iframe')[0];
      // iframe.contentWindow.onXingAuthLogin();
      const xbtn = iframe.contentWindow.document.getElementById('xing-login');
      xbtn.click();
    } catch (error) {
      this.spinner.hide('spContentLoad');
      this.sb.openFromComponent(SnackBottomComponent, {
        data: `XING-Plugin wurde nicht initialisiert, bitte aktualisieren Sie die Seite mit F5.`,
        ...sbWarnSett,
      });
    }

  }

  async signLinkedin() {
    if (!this.recaptchaValid) { return; }
    window.location.href = environment.linkedin.url;
  }

  async signLinkedinCallback() {
    const urlParams = new URLSearchParams(window.location.search);
    const authLinkedInResp: AuthLinkedInResp = {
      code: urlParams.get('code'),
      state: urlParams.get('state'),
      redirectUri: environment.linkedin.redirectUriPath,
    }
    if (
      !authLinkedInResp.code
      ||
      !authLinkedInResp.state
    ) {
      return;
    }
    this.spinner.show('spContentLoad');
    // console.log('waaa');
    console.log('this.authLinkedInResp', authLinkedInResp);

    console.log('before signLinkedin');
    try {
      const userAuthSocialParent = await this.auth.loginLinkedin(authLinkedInResp);
      if (!userAuthSocialParent) {
        this.auth.SignOut();
        this.spinner.hide('spContentLoad');
        return;
      }

      this.auth.initUser();
      this.data.footerShowOn();
      console.log('after signLinkedin');
      this.sb.openFromComponent(SnackBottomComponent, {
        data: `Linkedin: Sie wurden zum INFO.ZONE erfolgreich authentifiziert`,
        ...sbSuccessSett,
      });
      this.navigateAfterSignIn(userAuthSocialParent);
      this.spinner.hide('spContentLoad');
    } catch (error) {
      console.log(error);
      this.spinner.hide('spContentLoad');
      this.sb.openFromComponent(SnackBottomComponent, {
        data: `Linkedin-Fehler: ${error}`,
        ...sbWarnSett,
      });
      this.auth.SignOut();
      this.router.navigate(['/']);
    }
  }


  recaptchaResolved(captchaResponse: string): void {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.recaptchaValid = true;
  }

  private navigateAfterSignIn(userAuthSocialParent: IZAuthSocialParent) {
    // this.router.navigate(['/map']);
    if (userAuthSocialParent.createdAt) {
      this.router.navigate(['/user/my-list']);  
    }else{
      this.router.navigate(['/user/profile']);  
    }
    
  }

  // recaptchaError(errorDetails: RecaptchaErrorParameters): void {
  //   console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  //   this.sb.open(`reCAPTCHA: Fehler: ${errorDetails}`, 'x', sbWarnSett);
  //   this.recaptchaValid = false;
  // }

  ngOnDestroy(): void {
    const xingProp = 'xing';
    delete window[xingProp];
    delete window.onXingAuthLogin;
  }

}


