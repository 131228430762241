import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RouterModule } from '@angular/router';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { FlexLayoutModule } from '@angular/flex-layout';
import { environment } from 'src/environments/environment';


@NgModule({
  declarations: [HomeComponent],
  imports: [
    RouterModule,
    CommonModule,
    MatButtonModule,
    HttpClientModule,
    NgxSpinnerModule,
    // RecaptchaModule,
    RecaptchaV3Module,
    FlexLayoutModule,
  ],
  providers: [
    { provide: 'WindowO', useValue: window },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey }
  ]
})
export class HomeModule { }
