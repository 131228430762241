import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { MaterialModule } from './material/material.module';

/* Firebase services */
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';

import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';
import { FirestoreService } from './services/firestore.service';

/* Auth service */
// import { AuthenticationService } from './services/authentication.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeModule } from './ui/home/home.module';
import { HeaderModule } from './ui/a_header/header.module';
import { AngularFireStorage, AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PwaService } from './services/pwa.service';
import { FooterModule } from './ui/a_footer/footer.module';
import { NavigationModule } from './ui/navigation/navigation.module';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { JsonInterceptorService } from './services/json-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';

registerLocaleData(localeDe);     // de-DE


import { SharedModule } from './module/shared.module';
import { SnackBottomComponent } from './ui/dlg/snack-bottom/snack-bottom.component';
import { IZHammerConfig } from './helpers/iz-hammer';



@NgModule({
  declarations: [
    AppComponent,
    SnackBottomComponent,
  ],
  imports: [
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule.forRoot(),
    BrowserAnimationsModule,
    HeaderModule,
    HomeModule,
    FooterModule,
    NavigationModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    HammerModule,
    MatSnackBarModule,
  ],
  providers: [
    // AuthenticationService,
    AngularFirestore,
    AngularFireMessaging,
    FirestoreService,
    AngularFireStorage,
    PwaService,
    { provide: 'googleTagManagerId', useValue: environment.gtm.id },
    { provide: HTTP_INTERCEPTORS, useClass: JsonInterceptorService, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: IZHammerConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
