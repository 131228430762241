import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './ui/home/home.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'navigation',
    loadChildren: () => import('./ui/navigation/navigation.module').then(m => m.NavigationModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./ui/zones-ol/zones-ol.module').then(m => m.ZonesOlModule)
  },
  {
    path: 'user/my-list',
    loadChildren: () => import('./ui/zones-my/zones.module').then(m => m.ZonesModule)
  },
  {
    path: 'user/my-list/:uid',
    loadChildren: () => import('./ui/zones-my/zones.module').then(m => m.ZonesModule)
  },
  {
    path: 'zone/:uid',
    loadChildren: () => import('./ui/zones-my/zones.module').then(m => m.ZonesModule)
  },
  {
    path: 'user/gesamtliste',
    loadChildren: () => import('./ui/zones-all/zones-all.module').then(m => m.ZonesAllModule)
  },
  {
    path: 'user/archiv-list',
    loadChildren: () => import('./ui/zones-my/zones.module').then(m => m.ZonesModule)
  },
  {
    path: 'datenschutz',
    loadChildren: () => import('./static/privacy/privacy.module').then(m => m.PrivacyModule)
  },
  {
    path: 'agb',
    loadChildren: () => import('./static/conditions/conditions.module').then(m => m.ConditionsModule)
  },
  {
    path: 'impressum',
    loadChildren: () => import('./static/imprint/imprint.module').then(m => m.ImprintModule)
  },
  {
    path: 'ueber',
    loadChildren: () => import('./static/about/about.module').then(m => m.AboutModule)
  },
  {
    path: 'kontakt',
    loadChildren: () => import('./static/contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'vision',
    loadChildren: () => import('./static/vision/vision.module').then(m => m.VisionModule)
  },

  {
    path: 'user/bookmark',
    loadChildren: () => import('./ui/a_user/bookmark/bookmark.module').then(m => m.BookmarkModule)
  },
  {
    path: 'user/bookmark/:id',
    loadChildren: () => import('./ui/a_user/bookmark/bookmark.module').then(m => m.BookmarkModule)
  },
  // {
  //   path: 'user/bookmark/:id',
  //   loadChildren: () => import('./ui/a_user/bookmark/bookmark.module').then(m => m.BookmarkModule)
  // },
  {
    path: 'user/feed',
    loadChildren: () => import('./ui/a_user/feed/feed.module').then(m => m.FeedModule)
  },
  {
    path: 'user/profile',
    loadChildren: () => import('./ui/a_user/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'user/chats',
    loadChildren: () => import('./ui/a_user/chats/chats.module').then(m => m.ChatsModule)
  },
  {
    path: 'user/chat',
    loadChildren: () => import('./ui/a_user/chat/chat.module').then(m => m.ChatModule)
  },
  // ADMIN CUSTOMER
  {
    path: 'customer/login',
    loadChildren: () => import('./customer/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'customer',
    loadChildren: () => import('./customer/zone-list/zone-list.module').then(m => m.ZoneListModule),
    data: { role: 'customer' }
  },
  {
    path: 'customer/zone-list',
    loadChildren: () => import('./customer/zone-list/zone-list.module').then(m => m.ZoneListModule),
    data: { role: 'customer' }
  },
  {
    path: 'customer/zone-edit',
    loadChildren: () => import('./customer/zone-edit/zone-edit.module').then(m => m.ZoneEditModule),
    data: { role: 'customer' }
  },
  {
    path: 'customer/meldungen',
    loadChildren: () => import('./customer/notifi-list/notifi-list.module').then(m => m.NotifiListModule),
    data: { role: 'customer' }
  },
  {
    path: 'customer/customer-edit',
    loadChildren: () => import('./customer/customer-edit/customer-edit.module').then(m => m.CustomerEditModule),
    data: { role: 'customer' }
  },

  // ADMIN SUPER
  {
    path: 'admin/login',
    loadChildren: () => import('./admin/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/zone-list/zone-list.module').then(m => m.ZoneListModule),
    data: { role: 'admin' }
  },
  {
    path: 'admin/handshake-request-list',
    loadChildren: () => import('./admin/handshake-request-list/handshake-request-list.module').then(m => m.HandshakeRequestListModule),
    data: { role: 'admin' }
  },
  {
    path: 'admin/zone-list',
    loadChildren: () => import('./admin/zone-list/zone-list.module').then(m => m.ZoneListModule),
    data: { role: 'admin' }
  },
  {
    path: 'admin/zone-edit',
    loadChildren: () => import('./admin/zone-edit/zone-edit.module').then(m => m.ZoneEditModule),
    data: { role: 'admin' }
  },
  {
    path: 'admin/customer-list',
    loadChildren: () => import('./admin/customer-list/customer-list.module').then(m => m.CustomerListModule),
    data: { role: 'admin' }
  },
  {
    path: 'admin/customer-edit',
    loadChildren: () => import('./admin/customer-edit/customer-edit.module').then(m => m.CustomerEditModule),
    data: { role: 'admin' }
  },
  {
    path: 'admin/meldungen',
    loadChildren: () => import('./admin/notifi-list/notifi-list.module').then(m => m.NotifiListModule),
    data: { role: 'admin' }
  },
  {
    path: 'admin/datas/zone-ie',
    loadChildren: () => import('./admin/datas/zone-ie/zone-ie.module').then(m => m.ZoneIeModule),
    data: { role: 'admin' }
  },
  {
    path: 'admin/datas/customer-ie',
    loadChildren: () => import('./admin/datas/customer-ie/customer-ie.module').then(m => m.CustomerIeModule),
    data: { role: 'admin' }
  },
  {
    path: 'admin/datas/focuss',
    loadChildren: () => import('./admin/datas/focuss/focuss.module').then(m => m.FocussModule),
    data: { role: 'admin' }
  },
  {
    path: 'admin/datas/categorys',
    loadChildren: () => import('./admin/datas/categorys/categorys.module').then(m => m.CategorysModule),
    data: { role: 'admin' }
  },


  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
