export const DateFormat = {
    dateOnly: 'DD.MM.YYYY',
};

export interface IZRBType {
    v: number;
    t: string;
}

// export const ztypes: IZRBType[] = [
//     { v: 10, t: 'Free' },
//     { v: 20, t: 'Private' },
//     { v: 30, t: 'Public' },
//     { v: 50, t: 'Dummy' }
// ];

export const reportTypes: IZRBType[] = [
    { v: 1, t: 'unangemessene Fotos' },
    { v: 2, t: 'unangemessene Nachrichten' },
    { v: 3, t: 'andere Gründe' },
];

export const dlgCommentSett = {
    width: '90%',
    maxWidth: '700px',
    panelClass: 'iz-dlg-comment'
};


// export const dlgZoneReassignRequestSett = {
//     width: '90%',
//     maxWidth: '700px',
//     panelClass: 'iz-dlg-reason'
// };

export const dlgReportSett = {
    width: '90%',
    maxWidth: '700px',
    panelClass: 'iz-dlg-report'
};


export const dlgZoneFilterSett = {
    maxWidth: '592px',
    width: '98%',
    panelClass: 'dlg-options',
    autoFocus: false,
};

export const dlgProfileSett = {
    width: '90%',
    maxWidth: '700px',
    panelClass: 'iz-dlg-profile'
};



export const sbSuccessSett = {
    duration: 5000,
    panelClass: 'iz-sb-success'
};

export const sbWarnSett = {
    duration: 9000,
    panelClass: 'iz-sb-warn'
};

export const sbErrorSett = {
    duration: 9000,
    panelClass: 'iz-sb-error'
};
