import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SharesService } from 'src/app/services/shares.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @Input() drawer: any;

  constructor(
    public router: Router,
    public auth: AuthenticationService,
    public shares: SharesService,
  ) { }

  ngOnInit() {
  }

  // isAdmin() {
  //   return this.auth.userSocialParent?.roles?.includes('admin');
  // }

  navi(url: string) {
    this.drawer.toggle();
    this.router.navigate([url]);
  }

  // naviList(url: string, n: number) {
  //   this.drawer.toggle();
  //   this.router.navigate([url], { queryParams: { query: n } });
  // }

  async logout() {
    await this.auth.SignOut();
    this.navi('/');
  }

}
