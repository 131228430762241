import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';



@NgModule({})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [AuthenticationService]
    };
  }
}
