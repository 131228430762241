import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  public promptEvent: any;

  constructor(private swUpdate: SwUpdate, private spinner: NgxSpinnerService) {
    console.log('swUpdate', swUpdate);
    this.swUpdate.available.subscribe(() => {
      if (confirm('Eine neue Version ist verfügbar \nMöchten Sie aktualisieren?')) {
        this.spinner.show();
        window.location.reload();
      }
    });
    window.addEventListener('beforeinstallprompt', event => {
      this.promptEvent = event;
      console.log('promptEvent', this.promptEvent);
    });
  }
}
