import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataService } from 'src/app/services/data.service';
import { DaoUiService } from 'src/app/services/dao/dao-ui.service';
import { fhFade } from 'src/app/helpers/animations';
import { SharesService } from 'src/app/services/shares.service';
import { MatDrawer } from '@angular/material/sidenav';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [
    fhFade,
  ]
})
export class FooterComponent implements OnInit, OnDestroy {

  @Input() drawer: MatDrawer;

  public chatsMessagesCountNew = 0;
  public today: number = Date.now();

  public onFadeSate = 'fadeinFooter';

  constructor(
    public router: Router,
    private dao: DaoUiService,
    public auth: AuthenticationService,
    public data: DataService,
    public shares: SharesService,
  ) { }

  ngOnInit() {
    console.log('FooterComponent - ngOnInit', this.auth.userSocialParent);

    this.auth.GetUserParent$()
      .subscribe((u) => {
        console.log('FOOTER - ngOnInit - user', u);
        if (u) {
          this.dao.chatMessagesCountNewAll$()
            .pipe(untilDestroyed(this))
            .subscribe((count: number) => {
              console.log('FOOTER - chatsMessagesCountNew - ngOnInit - count', count);
              this.chatsMessagesCountNew = count;
            });
        }
      },
        (e) => {
          console.log(e);
        });

    // if (this.auth.userSocialParent) {
    //   this.dao.chatMessagesCountNewAll$()
    //     .pipe(untilDestroyed(this))
    //     .subscribe((count: number) => {
    //       console.log('FOOTER - ngOnInit - chatsMessagesCountNew - count', count);
    //       this.chatsMessagesCountNew = count;
    //     });
    // }

    this.data.footerShow$
      .subscribe((b: boolean) => {
        if (b) {
          this.onFadeSate = 'fadeinFooter';
        } else {
          this.onFadeSate = 'fadeoutFooter';
        }
      });
  }

  navigation() {
    // console.log('this.drawer - ', this.drawer);
    this.drawer.toggle();
  }

  ngOnDestroy(): void {
  }

}
