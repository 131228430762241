import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    public auth: AuthenticationService,
    public router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (!this.auth.GetUserParent$()) {
      return of(false);
    }
    return this.auth.GetUserParent$()
      .pipe(
        map(u => {
          // console.log('AUTHGUARDSERVICE:canActivate: - ', u);
          if (u) {
            return true;
          } else {
            this.router.navigate(['/home']);
          }
        }),
        catchError(() => {
          console.log('AUTHGUARDSERVICE:canActivate: - catchError');
          this.router.navigate(['/home']);
          return of(false);
        })
      );
  }
}
