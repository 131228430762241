import { Injectable } from '@angular/core';
import { Observable, of, forkJoin, combineLatest, OperatorFunction } from 'rxjs';
import { take, switchMap, map, catchError, tap, mergeAll } from 'rxjs/operators';
import { IZAuthSocialParent, IZAuthUser, IZBookmarkPre } from 'src/app/model/auth.model';
import { IZChat, IZChatMessage } from 'src/app/model/chat.model';
import { IZList } from 'src/app/model/list.model';
import { AuthenticationService } from '../authentication.service';
import { FirestoreService } from '../firestore.service';
import { DaoService } from './dao.service';
import { Query } from '@angular/fire/firestore';
import { IZZone } from 'src/app/model/zone.model';

import { v2Date } from '../toolz';
import { IZCUD, IZKid } from 'src/app/model/kid.model';
import { IZBookmark } from 'src/app/model/bookmark.model';
import { IZFeed } from 'src/app/model/feed.model';
import { IZUserZoneConn } from 'src/app/model/user-zone-conn.model';
import { IZZoneUserConn } from 'src/app/model/zone-user-conn';
import { IZZoneListMapSearch, IZZoneListUIFilter, IZZoneListUIQuery, IZListUIFilter, TabTopType } from 'src/app/model/filter.model';
import * as geofire from 'geofire-common';

import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

@Injectable({
  providedIn: 'root'
})
export class DaoUiService extends DaoService {



  // OperatorFunction<IZZone[], Observable<IZZone>[]>,
  private joinZonesKids$: [
    OperatorFunction<IZZone[], IZZone[]>,
    OperatorFunction<IZZone[], IZZone[]>,
    OperatorFunction<IZZone[], IZZone[]>,
    OperatorFunction<IZZone[], IZZone[]>,
    OperatorFunction<IZZone[], IZZone[]>,
  ] =
    [
      switchMap((zones: IZZone[]) => {
        if (!zones || zones.length === 0) {
          return of([]);
        }
        const zonesInner$ = zones.map((zoneInner: IZZone) => {
          if (!zoneInner) {
            return of(null);
          }
          return this.joinCategory$(zoneInner);
        });
        return combineLatest([...zonesInner$]);
      }),
      switchMap((zones: IZZone[]) => {
        if (!zones || zones.length === 0) {
          return of([]);
        }
        const zonesInner$ = zones.map((zoneInner: IZZone) => {
          if (!zoneInner) {
            return of(null);
          }
          return this.joinBookmark$(zoneInner, 'zones');
        });
        return forkJoin<Observable<IZZone>[]>([...zonesInner$]);
      }),
      switchMap((zones: IZZone[]) => {
        if (!zones || zones.length === 0) {
          return of([]);
        }
        const zonesInner$ = zones.map((zoneInner: IZZone) => {
          if (!zoneInner) {
            return of(null);
          }
          return this.joinFeed$(zoneInner);
        });
        return forkJoin<Observable<IZZone>[]>([...zonesInner$]);
      }),
      switchMap((zones: IZZone[]) => {
        if (!zones || zones.length === 0) {
          return of([]);
        }
        const zonesInner$ = zones.map((zoneInner: IZZone) => {
          if (!zoneInner) {
            return of(null);
          }
          return this.joinHandshakeRequest$(zoneInner);
        });
        return forkJoin<Observable<IZZone>[]>([...zonesInner$]);
      }),
      switchMap((zones: IZZone[]) => {
        if (!zones || zones.length === 0) {
          return of([]);
        }
        const zonesInner$ = zones.map((zoneInner: IZZone) => {
          if (!zoneInner) {
            return of(null);
          }
          return this.joinCheckinI$(zoneInner);
        });
        return forkJoin<Observable<IZZone>[]>([...zonesInner$]);
      }),
    ];




  constructor(
    protected auth: AuthenticationService,
    protected fb: FirestoreService,
  ) {
    super(auth, fb);
  }




  // ***************************************************************************************************************************
  // ZONES

  /**
   * zoneCheckin$
   * @param zoneUserConn -
   * @param userZoneKid -
   */
  async zoneCheckin$(zoneUserConn: IZZoneUserConn, userZoneKid: IZUserZoneConn): Promise<void> {
    await this.fb.upsert(`users/${zoneUserConn.uid}/zones/${userZoneKid.uid}`, userZoneKid);
    await this.fb.upsert(`zones/${userZoneKid.uid}/users/${zoneUserConn.uid}`, zoneUserConn);
  }

  /**
   * zoneCheckout$
   * @param zoneUid -
   */
  async zoneCheckout$(zoneUid: string) {
    await this.fb.delete(`zones/${zoneUid}/users/${this.auth.userSocialParent.uid}`);
    await this.fb.delete(`users/${this.auth.userSocialParent.uid}/zones/${zoneUid}`);
  }

  /**
   * zoneByUid$
   * @param uid -
   */
  public zoneByUid$(uid: string): Observable<IZZone> {
    return this.fb.doc$$<IZZone>(`zones/${uid}`)
      .pipe(
        switchMap((zone: IZZone) => {
          return this.joinCustomer$(zone);
        }),
        switchMap((zone: IZZone) => {
          return this.joinCategory$(zone);
        }),
        switchMap((zone: IZZone) => {
          return this.joinBookmark$(zone, 'zones');
        }),
        switchMap((zone: IZZone) => {
          return this.joinFeed$(zone);
        }),
        switchMap((zone: IZZone) => {
          return this.joinHandshakeRequest$(zone);
        }),
        switchMap((zone: IZZone) => {
          return this.joinCheckinI$(zone);
        }),
      );
  }

  /**
   * zonesMapFilter$
   * @param zoneList -
   * @param filter -
   */
  zonesMapFilter$(zoneList: IZList<IZZone>, filter: IZZoneListMapSearch): Observable<IZZone[]> {
    const zones$ = [];
    // const zoneList1 = zoneList[0];
    // const zoneList2 = zoneListA[1];
    // const zoneList3 = zoneListA[2];
    // const zoneList4 = zoneListA[3];

    filter.periodFromd = filter.periodFromd || new Date();
    filter.periodTilld = filter.periodTilld || new Date();

    // console.log('DAO:zonesMapFilter$ - filter - ', filter);

    zones$.push(
      this.fb.colWithIds$$(zoneList.path,
        (ref: Query) => {
          if (zoneList.limit) {
            ref = ref
              .limit(zoneList.limit)
              ;
          }

          // if no filter, skip it
          if (!filter) {
            if (zoneList.startAfter) {
              ref = ref.startAfter(zoneList.startAfter);
            }
            return ref;
          }

          if (filter.geohashNorthEasti && filter.geohashSouthWesti) {
            ref = ref
              // .where('geohashi', '>', filter.geohashNorthWesti)
              .where('geohashi', '<', filter.geohashNorthEasti)
              .where('geohashi', '>', filter.geohashSouthWesti)
              // .where('geohashi', '<', filter.geohashSouthEasti)
              ;
          }

          // zmode
          if (filter.zmode) {
            ref = ref.where('zmode', '==', filter.zmode);
          }

          if (zoneList.startAfter) {
            ref = ref.startAfter(zoneList.startAfter);
          }
          return ref;

        })
        .pipe(
          switchMap((zones: IZZone[]) => {
            zoneList.list = zones;
            return this.lastFound$(zoneList);
          }),
          ...this.joinZonesKids$,
        )
    );

    return this.zonesMapFilterJoin$(filter, zones$);

  }

  private zonesMapFilterJoin$(filter: IZZoneListMapSearch, zones$: any[]): Observable<IZZone[]> {
    return forkJoin(zones$)
      .pipe(
        map((listMerged: any[]) => {
          console.log('zonesMapFilterJoin$ - listMerged - ', listMerged);

          const listMergedSingle = listMerged.flat(1);
          // const listUniques = uniqueList(listMergedSingle);
          let listFilter: IZZone[] = this.zonesFilter(listMergedSingle, filter);


          // console.log('DAO:zonesMapFilter$ -  listFilter', listFilter);
          return listFilter;
        }),
      );

  }

  /**
 * zonesMapFilter$
 * @param zoneList -
 * @param filter -
 */
  zonesMapFilterBounds$(filter: IZZoneListMapSearch): Observable<IZZone[]> {
    console.log('zonesMapFilterBounds$ - filter - ', filter);

    const zones$: Observable<IZZone[]>[] = [];
    filter.periodFromd = filter.periodFromd || new Date();
    filter.periodTilld = filter.periodTilld || new Date();

    if (!this.auth.userSocialParent.lat || !this.auth.userSocialParent.lng) {
      return of([]);
    }

    const center = [this.auth.userSocialParent.lat, this.auth.userSocialParent.lng];
    const radiusInM = filter.radius || 1;
    console.log('zonesMapFilter$ - center + radiusInM - ', center, radiusInM);
    const bounds = geofire.geohashQueryBounds(center, radiusInM);

    for (const bound of bounds) {
      console.log('zonesMapFilter$ - bound ', bound);
      const o$ = this.fb.colWithIds$$<IZZone[]>('zones',
        (ref: Query) => {

          if (filter.zmode) {
            ref = ref.where('zmode', '==', filter.zmode);
          }
          ref = ref.orderBy('geohash');
          ref = ref.startAt(bound[0]);
          ref = ref.endAt(bound[1]);
          return ref;
        })
        .pipe(
          ...this.joinZonesKids$,
          catchError(_ => of([])),
        )

      zones$.push(
        o$
      );
    }
    return this.zonesMapFilterJoin$(filter, zones$);

  }


  /**
* zonesMapFilter$
* @param zoneList -
* @param bound -
*/
  private zonesMapFilterBound$(filter: IZZoneListMapSearch, bound: string[]): Observable<IZZone[]> {
    console.log('zonesMapFilter$ - bound ', bound);
    const o$ = this.fb.colWithIds$$<IZZone[]>('zones',
      (ref: Query) => {

        if (filter.zmode) {
          ref = ref.where('zmode', '==', filter.zmode);
        }
        ref = ref.orderBy('geohash');
        ref = ref.startAt(bound[0]);
        ref = ref.endAt(bound[1]);
        return ref;
      })
      .pipe(
        ...this.joinZonesKids$,
        catchError(_ => of([])),
      )

    return this.zonesMapFilterJoin$(filter, [o$]);

  }







  /**
   * zonesMapSearch$
   * @param zoneList -
   * @param filter -
   */
  zonesUI$(zoneList: IZList<IZKid>, filter: IZZoneListUIFilter): Observable<IZZone[]> {
    // const zones$: Observable<IZZone[]>[] = [];
    filter.listType = filter.listType || IZZoneListUIQuery.active;

    console.log('DAO:zonesUI$ - filter - ', filter);

    return this.fb.colWithIds$(
      zoneList.path,
      (ref: Query) => {
        switch (filter.listType) {
          case IZZoneListUIQuery.archive:
            ref = ref.where('sortPrio', '<', 0);
            ref = ref.orderBy('sortPrio', 'asc');
            ref = ref.orderBy('periodTilld', 'desc');
            ref = ref.orderBy('visibleTilld', 'desc');
            break;

          case IZZoneListUIQuery.active:
            ref = ref.where('sortPrio', '>', 0);
            ref = ref.orderBy('sortPrio', 'asc');
            ref = ref.orderBy('periodFromd', 'asc');
            ref = ref.orderBy('visibleFromd', 'desc');
            break;
        }

        // search filter
        // if (filter?.filters?.length) {
        //   ref = ref.where('filters', 'array-contains-any', filter.filters);
        // }

        ref = ref
          .limit(zoneList.limit)
          ;
        if (zoneList.startAfter) {
          ref = ref.startAfter(zoneList.startAfter);
        }
        return ref;
      }
    )
      .pipe(
        take(1),
        switchMap((list: IZKid[]) => {
          zoneList.list = list;
          return this.lastFound$(zoneList);
        }),
        switchMap((izKids: IZKid[]) => {
          if (!izKids?.length) {
            return of([]);
          }
          const zonesByKids$ = izKids.map((izKid: IZKid) => {
            return this.zoneByUid$(izKid.uid)
              // return this.fb.doc$<IZZone>(`zones/${bookmark.uid}`)
              .pipe(
                take(1),
                // map((zone: IZZone) => {
                //   return zone;
                // }),
                // switchMap((zone: IZZone) => {
                //   if (!zone) {
                //     return of(null);
                //   }
                //   return this.joinFeed$(zone);
                // }),
              );
          });
          return forkJoin([...zonesByKids$]);
        }),
        // map((zones: IZZone[]) => zones),
        ...this.joinZonesKids$,
        map((zones: IZZone[]) => {
          console.log('DAO:zonesUI$ -  zones', zones);
          console.log('DAO:zonesUI$ -  filter', filter);
          // if (filter?.filters) {
          zones = zones.filter((zone: IZZone) => {
            // if (zone?.filters) {
            //   if (filter.filters.every(r => zone.filters.includes(r))) {
            //     return zone;
            //   }
            // }
            let categorys = true;
            if (filter.keywords) { // keywords only
              const keywords = filter.keywords.split(',').map((kw: string) => kw.trim().toLocaleLowerCase());
              if (keywords?.length) {
                if (zone.tags.some(r => keywords.includes(r.toLocaleLowerCase()))) {
                  // -
                } else {
                  categorys = false;
                }
              } else {
                // -
              }
            }
            let filters = true;
            if (filter?.filters && zone?.filters) {
              if (filter.filters.every(r => zone.filters.includes(r))) {
                // -
              } else {
                filters = false;
              }
            }

            if (categorys && filters) {
              return true;
            }
          })
          // }
          console.log('DAO:zonesUI$ -  zones', zones);
          return zones;
        }),
      );
  }


  /**
  * zonesAll$
  * @param zoneList -
  * @param filter -
  */
  zonesAll$(zoneList: IZList<IZZone>, filter: IZZoneListMapSearch): Observable<IZZone[]> {
    // const zones$: Observable<IZZone[]>[] = [];

    console.log('DAO:zonesAll$ - filter - ', filter);

    return this.fb.colWithIds$(
      zoneList.path,
      (ref: Query) => {
        // ref = ref.orderBy('periodTilld', 'desc');
        // ref = ref.orderBy('visibleTilld', 'desc');
        ref = ref.orderBy('title', 'asc');
        ref = ref
          .limit(zoneList.limit)
          ;
        if (zoneList.startAfter) {
          ref = ref.startAfter(zoneList.startAfter);
        }
        return ref;
      }
    )
      .pipe(
        take(1),
        switchMap((list: IZZone[]) => {
          zoneList.list = list;
          return this.lastFound$(zoneList);
        }),
        ...this.joinZonesKids$,
        map((zones: IZZone[]) => {
          // console.log('DAO:zonesAll$ -  zones', zones);
          console.log('DAO:zonesAll$ -  filter', filter);


          let listFilter = this.zonesFilter(zones, filter);

          console.log('DAO:zonesAll$ -  listFilter', listFilter);
          return listFilter;
        }),
      );
  }


  /**
   * zoneUIUsers$
   * @param zone -
   */
  zoneUIUsers$(userList: IZList<IZZoneUserConn>, filter: IZListUIFilter): Observable<IZAuthSocialParent[]> {
    return this.fb.colWithIds$(
      userList.path,
      (ref: Query) => {
        ref = ref.orderBy('createdAt', 'desc');
        ref = ref
          .limit(userList.limit)
          ;
        if (userList.startAfter) {
          ref = ref.startAfter(userList.startAfter);
        }
        return ref;
      })
      .pipe(
        switchMap((list: IZZoneUserConn[]) => {
          userList.list = list;
          return this.lastFound$(userList);
        }),
        switchMap((kids: IZZoneUserConn[]) => {
          if (!kids || kids.length === 0) {
            return of([]);
          }
          const list$ = kids.map((kid: IZZoneUserConn) => {
            if (!kid) {
              return of(null);
            }
            return this.auth.GetUserParentByPriorityConn$(kid.uid, kid);
          });
          return combineLatest([...list$]);
        }),
        switchMap((users: IZAuthSocialParent[]) => {
          if (!users || users.length === 0) {
            return of([]);
          }
          const list$ = users.map((user: IZAuthSocialParent) => {
            if (!user) {
              return of(null);
            }
            return this.joinBookmark$(user, 'users');
          });
          return combineLatest([...list$]);
        }),
        switchMap((users: IZAuthSocialParent[]) => {
          if (!users || users.length === 0) {
            return of([]);
          }
          const list$ = users.map((user: IZAuthSocialParent) => {
            if (!user) {
              return of(null);
            }
            return this.joinHighlight$(user, 'users');
          });
          return combineLatest([...list$]);
        }),
        map((users: IZAuthSocialParent[]) => {
          return users.filter((user: IZAuthSocialParent) => !user.highlight);
        }),
        map((users: IZAuthSocialParent[]) => {
          if (filter?.filters) {
            users = users.filter((user: IZAuthSocialParent) => {
              if (user?.filters) {
                if (filter.filters.every(r => user.filters.includes(r))) {
                  return user;
                }
              }
            })
          }
          console.log('DAO:zoneUIUsers$ -  users', users);
          return users;
        }),
      );
  }


  /**
   * zoneUIUsersHighlight$
   * @param zone -
   */
  zoneUIUsersHighlight$(userListH: IZList<IZKid>, zone: IZZone, filter: IZListUIFilter): Observable<IZAuthSocialParent[]> {
    return this.fb.colWithIds$(
      userListH.path,
      (ref: Query) => {
        ref = ref.orderBy('createdAt', 'desc');
        ref = ref
          .limit(userListH.limit)
          ;
        if (userListH.startAfter) {
          ref = ref.startAfter(userListH.startAfter);
        }
        return ref;
      }
    )
      .pipe(
        // take(1),
        switchMap((list: IZKid[]) => {
          userListH.list = list;
          return this.lastFound$(userListH);
        }),
        switchMap((highlights: IZKid[]) => {
          if (!highlights?.length) {
            return of([]);
          }
          const users$ = highlights.map((highlight: IZKid) => {
            return this.fb.doc$(`zones/${zone.uid}/users/${highlight.uid}`)
              .pipe(
                take(1),
              );
          });
          return forkJoin([...users$]);
        }),
        map((kids: IZZoneUserConn[]) => {
          return kids.filter(Boolean);
        }),
        switchMap((kids: IZZoneUserConn[]) => {
          if (!kids || kids.length === 0) {
            return of([]);
          }
          const list$ = kids.map((kid: IZZoneUserConn) => {
            if (!kid) {
              return of(null);
            }
            return this.auth.GetUserParentByPriorityConn$(kid.uid, kid);
          });
          return combineLatest([...list$]);
        }),
        switchMap((users: IZAuthSocialParent[]) => {
          if (!users || users.length === 0) {
            return of([]);
          }
          const list$ = users.map((user: IZAuthSocialParent) => {
            if (!user) {
              return of(null);
            }
            return this.joinBookmark$(user, 'users');
          });
          return combineLatest([...list$]);
        }),
        switchMap((users: IZAuthSocialParent[]) => {
          if (!users || users.length === 0) {
            return of([]);
          }
          const list$ = users.map((user: IZAuthSocialParent) => {
            if (!user) {
              return of(null);
            }
            return this.joinHighlight$(user, 'users');
          });
          return combineLatest([...list$]);
        }),
        map((users: IZAuthSocialParent[]) => {
          if (filter?.filters) {
            users = users.filter((user: IZAuthSocialParent) => {
              if (user?.filters) {
                if (filter.filters.every(r => user.filters.includes(r))) {
                  return user;
                }
              }
            })
          }
          console.log('DAO:zoneUIUsersHighlight$ -  users', users);
          return users;
        }),
      );
  }

  /**
   * joinZonesKids$
   */
  // private joinZonesKids$(): OperatorFunction<IZZone[], Observable<IZZone>[]>[] {}


  /**
   * zonesFilter
   */
  private zonesFilter(zones: IZZone[], filter: IZZoneListMapSearch): IZZone[] {
    return zones.filter((zone: IZZone) => {
      // geo inside view-box
      // GEOHASH is used
      let geoVisible = true;
      if (!zone.geo.lng || !zone.geo.lat) {
        geoVisible = false;
      } else {
        if (filter?.geoSouthWest && filter?.geoNorthEast) {
          // console.log('zones - by gehash - ', listFilter);
          if (zone.geo.lng >= filter.geoSouthWest.lng
            && zone.geo.lng <= filter.geoNorthEast.lng
            && zone.geo.lat >= filter.geoSouthWest.lat
            && zone.geo.lat <= filter.geoNorthEast.lat) {
            // -  
          } else {
            geoVisible = false;
          }
        }
      }

      let geoRadiusAll = true;
      if (!zone.geo.lng || !zone.geo.lat) {
        geoRadiusAll = false;
      } else {
        if (filter?.radiusAll) {
          // console.log('zones - by gehash - ', listFilter);
          const distanceInKm = geofire.distanceBetween(
            [zone.geo.lat || 0, zone.geo.lng || 0],
            [this.auth.userSocialParent.lat, this.auth.userSocialParent.lng]
          );
          console.log('geoRadiusAll - ', zone.geo.lat || 0, zone.geo.lng || 0, distanceInKm);

          const distanceInM = distanceInKm * 1000;
          const radiusInM = (filter.radiusAll || 10) * 1000;
          if (distanceInM <= radiusInM) {
            // -  
          } else {
            geoRadiusAll = false;
          }
        }
      }

      let zoneVisibleFT = true;
      const zoneVisibleFromd = v2Date(zone.visibleFromd);
      const zoneVisibleTilld = v2Date(zone.visibleTilld);
      if (
        zoneVisibleFromd
        && zoneVisibleTilld
        && moment().isBetween(zoneVisibleFromd, zoneVisibleTilld, 'days', '[]')
      ) {
        // -
      } else {
        zoneVisibleFT = false;
      }


      let membersCount = true;
      // membersCount
      if (filter.membersCount) {
        if (zone.membersCount >= filter.membersCount) {
          // -
        } else {
          membersCount = false;
        }
      }

      let categorys = true;
      // categories & keywords
      if (filter.categorys?.length && filter.keywords) {
        const categoryRefs = filter.categorys; // .map((c: IZCategory) => c.uid);
        const keywords = filter.keywords.split(',').map((kw: string) => kw.trim());
        if (categoryRefs?.length && keywords?.length) {
          if (categoryRefs.includes(zone.refCategory) && zone.tags.some(r => keywords.includes(r))) {
            // -
          } else {
            categorys = false;
          }
        } else {
          // -
        }
      } else if (filter.categorys?.length) { // categories only
        const categoryRefs = filter.categorys;
        if (categoryRefs?.length) {
          if (categoryRefs.includes(zone.refCategory)) {
            // -
          } else {
            categorys = false;
          }
        } else {
          // -
        }
      } else if (filter.keywords) { // keywords only
        // listFilter = listFilter.filter((zone: IZZone) => {
        const keywords = filter.keywords.split(',').map((kw: string) => kw.trim().toLocaleLowerCase());
        if (keywords?.length) {
          if (zone.tags.some(r => keywords.includes(r.toLocaleLowerCase()))) {
            // -
          } else {
            categorys = false;
          }
        } else {
          // -
        }
      }

      let periodFT = true;
      if (filter.periodFromd && filter.periodTilld) {
        // listFilter = listFilter.filter((zone: IZZone) => {
        if (zone.periodFromd && zone.periodTilld) {
          const zonePeriodFromd = v2Date(zone.periodFromd);
          const zonePeriodTilld = v2Date(zone.periodTilld);
          console.log('choosedDate periodFromd - periodTilld ', moment(zonePeriodFromd).format('DD.MM.yyyy'), moment(zonePeriodTilld).format('DD.MM.yyyy'));
          const zoneRange = moment.range(zonePeriodFromd, zonePeriodTilld);
          const filterRange = moment.range(filter.periodFromd, filter.periodTilld);
          if (zoneRange.overlaps(filterRange)) {
            // -
          } else {
            periodFT = false;
          }
        }
      }

      let filters = true;
      if (filter?.filters) {
        if (zone?.filters) {
          if (filter.filters.every(r => zone.filters.includes(r))) {
            // -
          } else {
            filters = false;
          }
        }
      }

      if (geoVisible && geoRadiusAll && zoneVisibleFT && membersCount && categorys && periodFT && filters) {
        return true;
      }
    });
  }


  // ***************************************************************************************************************************
  // BOOKMARKS

  /**
   * zoneBookmark$
   * @param zone -
   * @param w -
   * @param bookmark -
   */
  async zoneBookmark$(zone: IZZone, w?: number, bookmark?: IZBookmark) {
    if (w === 1) {
      return this.fb.upsert(`users/${this.auth.userSocialParent.uid}/zonesBookmarks/${zone.uid}`, bookmark);
    } else {
      return this.fb.delete(`users/${this.auth.userSocialParent.uid}/zonesBookmarks/${zone.bookmark.refParent}`);
    }
  }


  /**
   * userBookmark$
   * @param user -
   * @param w -
   * @param bookmark -
   */
  async userBookmark$(user: IZAuthSocialParent, w?: IZCUD, bookmark?: IZBookmark) {
    if (w === IZCUD.upsert) {
      return this.fb.upsert(`users/${this.auth.userSocialParent.uid}/usersBookmarks/${user.uid}`, bookmark);
    } else {
      return this.fb.delete(`users/${this.auth.userSocialParent.uid}/usersBookmarks/${user.bookmark.refParent}`);
    }
  }


  /**
   * bookmarksMyРighlight$
   */
  bookmarksMyHighlight$(bookmarkListA: IZList<IZKid>[], filter: IZListUIFilter): Observable<IZBookmarkPre[]> {
    const bookmarkList1 = bookmarkListA[0];
    const bookmarkList2 = bookmarkListA[1];
    bookmarkList2.endPage = true; // reserve for zones, t
    bookmarkList2.endColl = true;  // reserve for zones, t
    console.log('bookmarksMyHighlight$ - start');

    return this.fb.colWithIds$$(bookmarkList1.path,
      (ref: Query) => {
        ref = ref.orderBy('createdAt', 'desc');
        ref = ref
          .limit(bookmarkList1.limit)
          ;
        if (bookmarkList1.startAfter) {
          ref = ref.startAfter(bookmarkList1.startAfter);
        }
        return ref;
      }
    )
      .pipe(
        // take(1),
        switchMap((list: IZKid[]) => {
          bookmarkList1.list = list;
          return this.lastFound$(bookmarkList1);
        }),
        switchMap((highlights: IZKid[]) => {
          if (!highlights?.length) {
            return of([]);
          }
          const bookmarks$ = highlights.map((highlight: IZBookmark) => {
            return this.fb.doc$(`users/${this.auth.userSocialParent.uid}/usersBookmarks/${highlight.uid}`)
              .pipe(
                take(1),
              );
          });
          return forkJoin([...bookmarks$]);
        }),
        map((kids: IZBookmark[]) => {
          return kids.filter(Boolean);
        }),
        switchMap((bookmarks: IZBookmark[]) => {
          if (!bookmarks?.length) {
            return of([]);
          }
          const bookmarks$ = bookmarks.map((bookmark: IZBookmark) => {
            return this.auth.GetUserParentByUUIDFull$(bookmark.uid)
              .pipe(
                take(1),
                map((user: IZAuthSocialParent) => {
                  if (!user) return user;
                  user.bookmark = bookmark;
                  return user;
                })
              );
          });
          return forkJoin([...bookmarks$]);
        }),
        map((users: IZAuthSocialParent[]) => {
          return users.filter(Boolean);
        }),
        switchMap((users: IZAuthSocialParent[]) => {
          if (!users || users.length === 0) {
            return of([]);
          }
          const list$ = users.map((user: IZAuthSocialParent) => {
            if (!user) {
              return of(null);
            }
            return this.joinHighlight$(user, 'users');
          });
          return forkJoin([...list$]);
        }),
        map((users: IZAuthSocialParent[]) => {
          // console.log('DAO:bookmarksMyHighlight$ -  zones', users);
          // console.log('DAO:bookmarksMyHighlight$ -  filter', filter);
          if (filter?.filters) {
            users = users.filter((user: IZAuthSocialParent) => {
              if (user?.filters) {
                if (filter.filters.every(r => user.filters.includes(r))) {
                  return user;
                }
              }
            })
          }
          console.log('DAO:bookmarksMyHighlight$ -  zones', users);
          return users;
        }),
      );

  }

  /**
   * bookmarksMy$
   */
  bookmarksMy$(bookmarkListA: IZList<IZBookmark>[], filter: IZZoneListUIFilter): Observable<(IZBookmarkPre)[]> {
    const bookmarkListUsers = bookmarkListA[0];
    const bookmarkListZones = bookmarkListA[1];

    let usersBookmarks$ = of([]);
    let zonesBookmarks$ = of([]);

    console.log('bookmarksMy$ - start');

    // FIXME: very bad boy, if too long
    if (filter?.tabTopType === TabTopType.users) {
      usersBookmarks$ = this.fb.colWithIds$$(bookmarkListUsers.path,
        (ref: Query) => {
          ref = ref.orderBy('createdAt', 'desc');
          ref = ref
            .limit(bookmarkListUsers.limit)
            ;
          if (bookmarkListUsers.startAfter) {
            ref = ref.startAfter(bookmarkListUsers.startAfter);
          }
          return ref;
        }
      )
        .pipe(
          // take(1),
          switchMap((list: IZBookmark[]) => {
            bookmarkListUsers.list = list;
            return this.lastFound$(bookmarkListUsers);
          }),
          switchMap((bookmarks: IZBookmark[]) => {
            if (!bookmarks?.length) {
              return of([]);
            }
            const bookmarks$ = bookmarks.map((bookmark: IZBookmark) => {
              return this.auth.GetUserParentByUUIDFull$(bookmark.uid)
                .pipe(
                  take(1),
                  map((user: IZAuthSocialParent) => {
                    if (!user) return user;
                    user.bookmark = bookmark;
                    return user;
                  })
                );
            });
            return forkJoin([...bookmarks$]);
          }),
          map((users: IZAuthSocialParent[]) => {
            return users.filter(Boolean);
          }),
          switchMap((users: IZAuthSocialParent[]) => {
            if (!users || users.length === 0) {
              return of([]);
            }
            const list$ = users.map((user: IZAuthSocialParent) => {
              if (!user) {
                return of(null);
              }
              return this.joinHighlight$(user, 'users');
            });
            return forkJoin([...list$]);
          }),
          map((users: IZAuthSocialParent[]) => {
            return users.filter((user: IZAuthSocialParent) => !user.highlight);
          }),
        );
    } else {
      bookmarkListUsers.endColl = true;
      bookmarkListUsers.endPage = true;
    }

    // FIXME: very bad boy, if too long
    if (filter?.tabTopType === TabTopType.zones) {
      zonesBookmarks$ = this.fb.colWithIds$(bookmarkListZones.path,
        (ref: Query) => {
          ref = ref.orderBy('createdAt', 'desc');
          ref = ref
            .limit(bookmarkListZones.limit)
            ;
          if (bookmarkListZones.startAfter) {
            ref = ref.startAfter(bookmarkListZones.startAfter);
          }
          return ref;
        }
      )
        .pipe(
          take(1),
          switchMap((list: IZBookmark[]) => {
            bookmarkListZones.list = list;
            return this.lastFound$(bookmarkListZones);
          }),
          switchMap((bookmarks: IZBookmark[]) => {
            if (!bookmarks?.length) {
              return of([]);
            }
            const bookmarks$ = bookmarks.map((bookmark: IZBookmark) => {
              return this.zoneByUid$(bookmark.uid)
                // return this.fb.doc$<IZZone>(`zones/${bookmark.uid}`)
                .pipe(
                  take(1),
                  map((zone: IZZone) => {
                    const zoneVisibleTilld = v2Date(zone.visibleTilld);
                    if (zoneVisibleTilld && moment().isBefore(zoneVisibleTilld)) {
                      zone.bookmark = bookmark;
                      return zone;
                    }
                  }),
                  switchMap((zone: IZZone) => {
                    if (!zone) {
                      return of(null);
                    }
                    return this.joinFeed$(zone);
                  }),
                );
            });
            return forkJoin([...bookmarks$]);
          }),

        );
    } else {
      bookmarkListZones.endColl = true;
      bookmarkListZones.endPage = true;
    }


    return forkJoin([usersBookmarks$, zonesBookmarks$])
      .pipe(
        map(([usersBookmarks, zonesBookmarks]) => {
          console.log('map1 - usersBookmarks - ', usersBookmarks);
          console.log('map1 - zonesBookmarks$ - ', zonesBookmarks);
          return [...zonesBookmarks, ...usersBookmarks].filter(Boolean);
        }),
        map((bookmarks: IZBookmarkPre[]) => {
          return bookmarks.sort((a: IZBookmarkPre, b: IZBookmarkPre) => {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return v2Date(a.createdAt).getTime() - v2Date(b.createdAt).getTime();
          });
        }),
        map((bookmarks: IZBookmarkPre[]) => {
          // console.log('DAO:bookmarksMy$ -  bookmarks', bookmarks);
          // console.log('DAO:bookmarksMy$ -  filter', filter);
          if (filter?.filters) {
            bookmarks = bookmarks.filter((bookmark: IZBookmarkPre) => {
              if (bookmark?.filters) {
                if (filter.filters.every(r => bookmark.filters.includes(r))) {
                  return bookmark;
                }
              }
            })
          }
          console.log('DAO:bookmarksMy$ -  bookmarks', bookmarks);
          return bookmarks;
        }),
      );
  }

  /**
   * zoneFeed$
   * @param zone -
   * @param w -
   * @param feed -
   */
  async zoneFeed$(zone: IZZone, w?: IZCUD, feed?: IZFeed) {
    if (w === IZCUD.upsert) {
      return this.fb.upsert(`users/${this.auth.userSocialParent.uid}/zonesFeeds/${zone.uid}`, feed);
    } else {
      return this.fb.delete(`users/${this.auth.userSocialParent.uid}/zonesFeeds/${zone.feed.refParent}`);
    }
  }



  /**
   * feedsMy$
   */
  feedsMy$(feedList: IZList<IZFeed>, filter?: IZZoneListUIFilter): Observable<IZZone[]> {
    console.log('DAO:feedsMy$ -  filter', filter);
    return this.fb.colWithIds$$(`users/${this.auth.userSocialParent.uid}/zonesFeeds`,
      (ref: Query) => {
        ref = ref.orderBy('createdAt', 'desc');
        ref = ref
          .limit(feedList.limit)
          ;
        if (feedList.startAfter) {
          ref = ref.startAfter(feedList.startAfter);
        }
        return ref;
      }
    )
      .pipe(
        // take(1),
        switchMap((feeds: IZFeed[]) => {
          feedList.list = feeds;
          return this.lastFound$(feedList);
        }),
        switchMap((feeds: IZFeed[]) => {
          if (!feeds?.length) {
            return of([]);
          }
          const feeds$ = feeds.map((feed: IZFeed) => {
            return this.zoneByUid$(feed.uid)
              .pipe(
                take(1),
                map((zone: IZZone) => {
                  zone.feed = feed;
                  return zone;
                }),
              );
          });
          return forkJoin([...feeds$]);
        }),
        map((zones: IZZone[]) => {
          console.log('DAO:feedsMy$ -  zones', zones);
          zones = zones.filter((zone: IZZone) => {
            const zoneVisibleTilld = v2Date(zone.visibleTilld);
            if (zoneVisibleTilld && moment().isBefore(zoneVisibleTilld)) {
              return true;
            }
          })
          if (filter?.filters) {
            zones = zones.filter((zone: IZZone) => {
              if (zone?.filters) {
                if (filter.filters.every(r => zone.filters.includes(r))) {
                  return zone;
                }
              }
            })
          }
          console.log('DAO:zonesUI$ -  zones', zones);
          return zones;
        }),
      );

  }


  /**
   * userHighlight$
   * @param user -
   * @param w -
   * @param highlight -
   */
  async userHighlight$(user: IZAuthSocialParent, w?: IZCUD, highlight?: IZKid) {
    if (w === IZCUD.upsert) {
      return await this.fb.upsert(`users/${this.auth.userSocialParent.uid}/usersHighlights/${user.uid}`, highlight);
    } else {
      return await this.fb.delete(`users/${this.auth.userSocialParent.uid}/usersHighlights/${user.highlight.refParent}`);
    }
  }

  /**
   * userHighlight$Count$
   */
  // userHighlightsCount$(): Observable<number> {
  //   return this.fb.colWithIdsDoc$(`users/${this.auth.userSocialParent.uid}/usersHighlights`)
  //     .pipe(
  //       take(1),
  //       map((kids: IZKid[]) => {
  //         return kids?.length || 0;
  //       }),
  //     );
  // }




  // ***************************************************************************************************************************
  // CHATS


  /**
   * chatsMyHighlight$
   */
  chatsMyHighlight$(chatListH: IZList<IZKid>, filter: IZListUIFilter): Observable<IZChat[]> {
    return this.fb.colWithIds$(chatListH.path,
      (ref: Query) => {
        ref = ref.orderBy('createdAt', 'desc');
        ref = ref
          .limit(chatListH.limit)
          ;
        if (chatListH.startAfter) {
          ref = ref.startAfter(chatListH.startAfter);
        }
        return ref;
      }
    )
      .pipe(
        take(1),
        switchMap((list: IZKid[]) => {
          chatListH.list = list;
          return this.lastFound$(chatListH);
        }),
        switchMap((highlights: IZKid[]) => {
          if (!highlights?.length) {
            return of([]);
          }
          const chats$ = highlights.map((highlight: IZBookmark) => {
            const uidChat = this.uidChat(this.auth.userSocialParent.uid, highlight.uid);
            return this.fb.doc$(`chats/${uidChat}`)
              .pipe(
                take(1),
              );
          });
          return forkJoin([...chats$]);
        }),
        map((kids: IZChat[]) => {
          return kids.filter(Boolean);
        }),
        switchMap((chats: IZChat[]) => {
          console.log(`CHATS:  switchMap -1- chats - `, chats);
          if (!chats?.length) {
            return of([]);
          }
          const chats$ = chats.map((chat: IZChat) => {
            if (!chat) {
              return of(null);
            }
            // console.log(`CHATS: chats/${chat.uid}/messages`);
            return this.fb.colWithIds$(`chats/${chat.uid}/messages`,
              (ref: Query) => {
                return ref.where('status', '==', 1).where('uidUserTo', '==', this.auth.userSocialParent.uid);
              }
            )
              .pipe(
                take(1),
                map((messages: IZChatMessage[]) => {
                  chat.messagesCountNew = 0;
                  if (messages?.length > 0) {
                    chat.messagesCountNew = messages.length;
                  }
                  return chat;
                })
              );
          });
          return forkJoin([...chats$]);
        }),
        switchMap((chats: IZChat[]) => {
          if (!chats?.length) {
            return of([]);
          }
          const chats$ = chats.map((chat: IZChat) => {
            const uid = chat.uidUser1 === this.auth.userSocialParent.uid ? chat.uidUser2 : chat.uidUser1;
            return this.auth.GetUserParentByUUIDFull$(uid)
              .pipe(
                take(1),
                switchMap((user: IZAuthSocialParent) => {
                  if (!user) {
                    return of(null);
                  }
                  return this.joinHighlight$(user, 'users');
                }),
                map((user: IZAuthSocialParent) => {
                  chat.user = user;
                  return chat;
                })
              );
          });
          return forkJoin([...chats$]);
        }),
        map((chats: IZChat[]) => {
          if (filter?.filters) {
            chats = chats.filter((chat: IZChat) => {
              if (chat?.user?.filters) {
                if (filter.filters.every(r => chat.user.filters.includes(r))) {
                  return chat;
                }
              }
            })
          }
          console.log('DAO:chatsMyHighlight$ -  chats', chats);
          return chats;
        }),
      );
  }


  /**
   * chatsMy$
   */
  chatsMy$(chatList: IZList<IZChat>, filter: IZListUIFilter): Observable<IZChat[]> {
    return this.fb.colWithIds$$(chatList.path,
      (ref: Query) => {
        ref = ref.where('uids', 'array-contains', this.auth.userSocialParent.uid).orderBy('updatedAt', 'desc');
        ref = ref
          .limit(chatList.limit)
          ;
        if (chatList.startAfter) {
          ref = ref.startAfter(chatList.startAfter);
        }
        return ref;
      })
      .pipe(
        // take(1),
        switchMap((list: IZChat[]) => {
          chatList.list = list;
          return this.lastFound$(chatList);
        }),
        switchMap((chats: IZChat[]) => {
          if (!chats?.length) {
            return of([]);
          }
          const chats$ = chats.map((chat: IZChat) => {
            const uid = chat.uidUser1 === this.auth.userSocialParent.uid ? chat.uidUser2 : chat.uidUser1;
            return this.auth.GetUserParentByUUIDFull$(uid)
              .pipe(
                take(1),
                switchMap((user: IZAuthSocialParent) => {
                  if (!user) {
                    return of(null);
                  }
                  return this.joinHighlight$(user, 'users');
                }),
                map((user: IZAuthSocialParent) => {
                  if (!user.highlight) {
                    chat.user = user;
                    return chat;
                  } else {
                    return null;
                  }
                })
              );
          });
          return forkJoin([...chats$]);
        }),
        map((chats: IZChat[]) => chats.filter(Boolean)),
        switchMap((chats: IZChat[]) => {
          console.log(`CHATS:  switchMap -1- chats - `, chats);
          if (!chats?.length) {
            return of([]);
          }
          const chats$ = chats.map((chat: IZChat) => {
            if (!chat) {
              return of(null);
            }
            // console.log(`CHATS: chats/${chat.uid}/messages`);
            return this.fb.colWithIds$$(`chats/${chat.uid}/messages`,
              (ref: Query) => {
                return ref.where('status', '==', 1).where('uidUserTo', '==', this.auth.userSocialParent.uid);
              }
            )
              .pipe(
                // take(1),
                map((messages: IZChatMessage[]) => {
                  chat.messagesCountNew = 0;
                  if (messages?.length > 0) {
                    chat.messagesCountNew = messages.length;
                  }
                  return chat;
                })
              );
          });
          return forkJoin([...chats$]);
        }),
        map((chats: IZChat[]) => {
          if (filter?.filters) {
            chats = chats.filter((chat: IZChat) => {
              if (chat?.user?.filters) {
                if (filter.filters.every(r => chat.user.filters.includes(r))) {
                  return chat;
                }
              }
            })
          }
          console.log('DAO:chatsMyHighlight$ -  chats', chats);
          return chats;
        }),
      );
  }


  /**
   * chatMessagesMy$
   * @param uid -
   */
  chatMessagesMy$(messageList: IZList<IZChatMessage>, uid: string): Observable<IZChatMessage[]> {
    console.log('chatMessagesMy$ - messageList - ', messageList);
    return this.fb.colWithIds$(`chats/${uid}/messages`,
      (ref: Query) => {
        ref = ref.orderBy('createdAt', 'desc');
        ref = ref.limit(messageList.limit);
        if (messageList.startAfter) {
          ref = ref.startAfter(messageList.startAfter);
        }
        return ref;
      })
      .pipe(
        // take(1),
        switchMap((list: IZChatMessage[]) => {
          messageList.list = list;
          return this.lastFound$(messageList);
        }),
        map((messages: IZChatMessage[]) => {
          console.log('chatMessagesMy$ - messages - ', messages);
          // return messages.reverse();
          messages.reverse();
          return messages.filter(m => m.type === 'added');
        }),
      );
  }

  /**
   * chatMessagesRead$ - set all my new messages to read
   * @param uid -
   */
  chatMessagesRead$(uid: string) {
    // set all my new messages to read
    this.fb.colWithIds$$(`chats/${uid}/messages`, (ref: Query) => {
      return ref.where('status', '==', 1).where('uidUserTo', '==', this.auth.userSocialParent.uid);
    })
      .pipe(
        // take(1)
      )
      .subscribe((messages: IZChatMessage[]) => {
        messages.forEach((message: any) => {
          this.fb.upsert(`chats/${uid}/messages/${message.uid}`, { status: 0 });
        });
      });
  }

  /**
   * chatMessagesCount$
   * @param uid -
   */
  chatMessagesCount$(uid: string): Observable<number> {
    return this.fb.colWithIds$(`chats/${uid}/messages`)
      .pipe(
        // take(1),
        map((messages: IZChatMessage[]) => {
          return messages?.length || 0;
        }),
      );
  }

  /**
   * chatMessagesCountNew$
   * @param uid -
   */
  chatMessagesCountNew$(uid: string): Observable<number> {
    return this.fb.colWithIds$(`chats/${uid}/messages`,
      (ref: Query) => {
        return ref.where('status', '==', 1).where('uidUserTo', '==', this.auth.userSocialParent.uid);
      })
      .pipe(
        // take(1),
        map((messages: IZChatMessage[]) => {
          return messages?.length || 0;
        }),
      );
  }

  /**
   * chatMessagesMy$
   */
  chatMessagesCountNewAll$(): Observable<number> {
    return this.fb.colWithIds$(`chats`,
      (ref: Query) => {
        return ref.where('uids', 'array-contains', this.auth.userSocialParent.uid).orderBy('updatedAt', 'desc');
      })
      .pipe(
        // take(1),
        switchMap((chats: IZChat[]) => {
          // console.log(`FOOTER: switchMap -1- chats - `, chats);
          if (!chats || chats.length === 0) {
            return of([]);
          }
          const chats$ = chats.map((chat: IZChat) => {
            // console.log(`FOOTER: chats/${chat.uid}/messages`);
            return this.chatMessagesCountNew$(chat.uid);
          });
          return combineLatest([...chats$]);
        }),
        map((counts: number[]) => {
          const numOr0 = n => isNaN(n) ? 0 : n;
          return counts.reduce((a, b) => numOr0(a) + numOr0(b), 0);
        })
      );
  }


}
