import { InfoZone, InfoZoneFactory } from './info-zone.model';

export interface IZKid extends InfoZone {
    refParent: string;
}

export class KidFactory extends InfoZoneFactory {
    static create(o: Partial<IZKid>): IZKid {
        return {
            ...super.create(o),
            refParent: o.refParent,
        };
    }

    static mapTo(o: IZKid): void {
        super.mapTo(o);
    }
}


// CUD Types (create / update / delete)
export enum IZCUD {
    upsert = 1,
    delete = 5,
}