import { ElementRef, Inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { IZAuthSocial, IZAuthSocialParent } from '../model/auth.model';
import { BookmarkFactory } from '../model/bookmark.model';
import { FeedFactory } from '../model/feed.model';
import { IZCUD, KidFactory } from '../model/kid.model';
import { SliderMenuState } from '../model/slider-menu.model';
import { IZZone } from '../model/zone.model';
import { AuthenticationService } from './authentication.service';
import { DaoUiService } from './dao/dao-ui.service';
import { DataService } from './data.service';
import { SliderMenuService } from './slider-menu.service';

@Injectable({
  providedIn: 'root'
})
export class SharesService {

  placeholder = '';
  noticeCurrent = '';
  scrollTopLast = 0;

  zoneMembersOpened: IZZone;

  constructor(
    private dao: DaoUiService,
    private auth: AuthenticationService,
    private sb: MatSnackBar,
    public router: Router,
    @Inject('WindowO') private window: Window,
    private data: DataService,
    private sliderMenuService: SliderMenuService,
    private deviceService: DeviceDetectorService,
  ) { }

  isPwaApp() {
    return ['fullscreen', 'standalone', 'minimal-ui'].some(
      (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
    );
  }

  izLink(e: Event, izzb: IZZone) {
    e.stopPropagation();
    // console.log('LIST:linkBeacon - ', beaconContnent.link);
    if (izzb.link) {
      this.openUrl(izzb.link);
    }
  }

  /**
   * openUrl
   * @param link -
   */
  openUrl(link: string) {
    let url = '';
    if (!/^http[s]?:\/\//.test(link)) {
      url = 'http://';
    }
    url += link;
    // console.log('LIST:linkBeacon - ', url);
    window.open(url, '_blank');
  }

  async izShare(izzb: IZZone) {
    // if (!izzb.link) { return; }
    // const sh = (window.navigator as Navigator);
    const url = environment.host + 'zone/' + izzb.uid;
    if (window?.navigator?.share) {
      const share = {
        title: izzb.title,
        text: izzb.title + ': ' + izzb.description,
        url
      };
      try {
        await window.navigator.share(share);
      } catch (err) {
        console.log(`LIST:shareZone: Couldn't share because of`, err.message);
      }
    } else {
      console.log('LIST:shareZone: web share not supported');
      try {
        await navigator.clipboard.writeText(url);
        this.sb.open(`Der Link "${izzb.title}" wurde in die Zwischenablage kopiert`, '', {
          duration: 3000,
        });
        console.log('LIST:shareZone: Page URL copied to clipboard');
      } catch (err) {
        console.error('LIST:shareZone: Failed to copy: ', err);
      }
    }
  }


  /**
   *
   * @param zone -
   */
  async zoneBookmark(zone: IZZone) {
    console.log('zoneBookmark - ', zone.bookmark);
    if (zone.bookmark) {
      await this.dao.zoneBookmark$(zone);
      delete zone.bookmark;
    } else {
      const bookmark = BookmarkFactory.create({
        uid: zone.uid,
        createdBy: this.auth.userSocialParent.uid,
        refParent: zone.uid,
        notice: ''
      });
      await this.dao.zoneBookmark$(zone, IZCUD.upsert, bookmark);
      zone.bookmark = bookmark;
    }
  }


  /**
   * -
   * @param zone -
   * @param notice -
   */
  izNotice(zone: IZZone, notice: string) {
    console.log('izNotice - ', notice);
    if (!notice && !zone.bookmark) { return; }
    if (zone.bookmark) {
      zone.bookmark.notice = notice;
    } else {
      const bookmark = BookmarkFactory.create({
        uid: this.dao.uid(),
        createdBy: this.auth.userSocialParent.uid,
        refParent: zone.uid,
        notice
      });
      zone.bookmark = bookmark;
    }
    this.dao.zoneBookmark$(zone, IZCUD.upsert, zone.bookmark);
  }

  /**
   * zoneFeed
   * @param zone -
   */
  async zoneFeed(zone: IZZone) {
    console.log('zoneFeed - ', zone.feed);
    if (zone.feed) {
      await this.dao.zoneFeed$(zone);
      delete zone.feed;
    } else {
      const feed = FeedFactory.create({
        uid: zone.uid,
        createdBy: this.auth.userSocialParent.uid,
        refParent: zone.uid,
        pushNotifi: true,
      });
      await this.dao.zoneFeed$(zone, IZCUD.upsert, feed);
      zone.feed = feed;
    }
  }



  /**
   *
   * @param user -
   */
  async userBookmark(user: IZAuthSocialParent) {
    console.log('userBookmark - ', user.bookmark);
    if (user.bookmark) {
      this.dao.userBookmark$(user);
      delete user.bookmark;
    } else {
      const bookmark = BookmarkFactory.create({
        uid: user.uid,
        createdBy: this.auth.userSocialParent.uid,
        refParent: user.uid,
        notice: ''
      });
      this.dao.userBookmark$(user, IZCUD.upsert, bookmark);
      user.bookmark = bookmark;
    }
  }

  userLinkSocial(userSocial: IZAuthSocial) {
    console.log('SHARES:userLinkSocial:user', userSocial);
    if (userSocial?.visible && userSocial?.link) {
      let url = '';
      if (!/^http[s]?:\/\//.test(userSocial.link)) {
        url += 'http://';
      }
      url += userSocial.link;
      window.open(url, '_blank');
    }
  }

  /**
   * -
   * @param user -
   * @param notice -
   */
  userNotice(user: IZAuthSocialParent, notice: string) {
    console.log('userNotice - ', notice);
    if (!notice && !user.bookmark) { return; }
    if (user.bookmark) {
      user.bookmark.notice = notice;
    } else {
      const bookmark = BookmarkFactory.create({
        uid: user.uid,
        createdBy: this.auth.userSocialParent.uid,
        refParent: user.uid,
        notice,
      });
      user.bookmark = bookmark;
    }
    this.dao.userBookmark$(user, IZCUD.upsert, user.bookmark);
  }

  /**
   * userHighlight
   * @param user -
   */
  userHighlight(user: IZAuthSocialParent) {
    console.log('userHighlight - ', user.highlight);
    if (user.highlight) {
      this.dao.userHighlight$(user, IZCUD.delete);
      delete user.highlight;
    } else {
      const highlight = KidFactory.create({
        uid: user.uid,
        createdBy: this.auth.userSocialParent.uid,
        refParent: user.uid,
      });
      this.dao.userHighlight$(user, IZCUD.upsert, highlight);
      user.highlight = highlight;
    }
  }



  public async edown(event: any, bookmark: any) {
    console.log(event);
    if (event.ctrlKey && event.key === 'Enter') {
      // this.saveNotice(bookmark);
    }
  }

  public eplaceholder(what: number, bookmark: any) {
    if (what === 1) {
      this.noticeCurrent = bookmark.notice;
    } else {
      // this.placeholder = 'max. 200 Zeichen';
      if (this.noticeCurrent !== bookmark.notice) {
        // this.saveNotice(bookmark);
      }
    }
  }

  public userChatTo(user: IZAuthSocialParent) {
    localStorage.setItem('uuid2', user.uid);
    this.router.navigate(['/user/chat']);
  }

  /**
   * zbsScroll
   * @param event -
   */
  public zbsScroll(event: Event) {
    if (!event.target) { return; }
    if (this.deviceService.isDesktop()) { return }
    const scrollTop = (event.target as HTMLElement).scrollTop;
    // console.log('zbsScroll', scrollTop, this.scrollTopLast);
    if (scrollTop > 100 && scrollTop > this.scrollTopLast) {
      // down
      this.data.headerShowOff();
      this.data.footerShowOff();
      this.sliderMenuService.toggle(SliderMenuState.Right);
    } else if ((scrollTop) < 1) {
      // } else if ((scrollTop + 30) < this.scrollTopLast) {
      // up
      this.data.headerShowOn();
      this.data.footerShowOn();
      this.sliderMenuService.toggle(SliderMenuState.Left);
    }
    this.scrollTopLast = scrollTop;
  }

  /**
   * zbsPanDown
   */
  zbsPanDown() {
    console.log('zbsPanUp');
    this.data.headerShowOn();
    this.data.footerShowOn();
  }

  /**
   * zbsPanUp
   */
  zbsPanUp() {
    console.log('zbsPanDown');
    this.data.headerShowOff();
    this.data.footerShowOff();
  }

  /**
   * loadXingScript
   * @param xingloadElementRef -
   */
  public loadXingScript(xingloadElementRef: ElementRef) {
    // this.xingloadElementRef.nativeElement.html = '';
    // tslint:disable-next-line: no-string-literal
    console.log('loadXingScript - ', this.window['xing']);
    // tslint:disable-next-line: no-string-literal
    if (this.window['xing']) {
      // tslint:disable-next-line: no-string-literal
      delete this.window['xing'];
      // console.log('loadXingScript - already loaded');
    }
    if (xingloadElementRef?.nativeElement) {
      while (xingloadElementRef.nativeElement.firstChild) {
        xingloadElementRef.nativeElement.firstChild.remove();
      }
    }

    // if (xingloadElementRef.nativeElement) {
    //   xingloadElementRef.nativeElement.remove();
    // }
    const scriptData = document.createElement('script');
    scriptData.innerHTML = '{"consumer_key": "' + environment.xing.consumer_key + '"}';
    scriptData.id = 'lwx';
    scriptData.type = 'xing/login';
    xingloadElementRef.nativeElement.appendChild(scriptData);
    // scriptData.onload = () => {
    console.log('ngAfterViewInit - loadXingScript scriptData');
    const scriptXing = document.createElement('script');
    scriptXing.innerHTML = '';
    scriptXing.id = 'lwx';
    scriptXing.src = environment.xing.src;
    scriptXing.async = true;
    scriptXing.defer = true;
    xingloadElementRef.nativeElement.appendChild(scriptXing);
    scriptXing.onload = () => {
      console.log('ngAfterViewInit - loadXingScript scriptXing');
    };
    // };
  }

  /**
   * sbDismiss
   * @param snackBarRef -
   */
  sbDismiss(snackBarRef: MatSnackBarRef<TextOnlySnackBar>) {
    console.log('The snack-bar add action!');
    snackBarRef.onAction()
      .subscribe(() => {
        console.log('The snack-bar action was triggered!');
      });
  }

}
