import { IZKid, KidFactory } from './kid.model';

export interface IZBookmark extends IZKid {
    notice?: string;
}


export class BookmarkFactory extends KidFactory {
    static create(o: Partial<IZBookmark>): IZBookmark {
        return {
            ...super.create(o),
            notice: o.notice || '',
        };
    }

    static mapTo(o: IZBookmark): void {
        super.mapTo(o);
    }
}