import { QueryDocumentSnapshot } from '@angular/fire/firestore';
import firebase from 'firebase';
import { IZCategory } from './category.model';
import { IZFocus } from './focus.model';

export interface FBFResponse {
    status: number;
    msg: string;
    error: string;
    err: string;
    token: string;
}


export interface InfoZone {

    readonly uid: string;
    readonly createdBy: string;
    createdAt?: firebase.firestore.Timestamp;
    updatedBy?: string;
    updatedAt?: firebase.firestore.Timestamp;

    // publicAt?: firebase.firestore.Timestamp;
    // status?: number;
    filters?: string[];

    // for pagination, will be remove before set in document
    doc?:  QueryDocumentSnapshot<any>;
    pathp?: string;
    type?: 'added' | 'modified' | 'removed';
}

export abstract class InfoZoneFactory {
    static create(o: Partial<InfoZone>): InfoZone {
        return {
            uid: o.uid,
            createdBy: o.createdBy,
        };
    }
    static mapTo(o: InfoZone): void {
        delete o.doc;
        delete o.type;
        delete o.pathp;
    }
}



export interface IZKeywords extends InfoZone {
    focuss: IZFocus[];
    categorys: IZCategory[];
}


// // UI
// export interface IZSlider {
//     uid: string;
//     sliderStates: SliderStates[];
// }

// export enum SliderStates {
//     slideStateRight = 'slideStateRight',
//     slideStateCenter = 'slideStateCenter',
//     slideStateLeft = 'slideStateLeft',
//     slideMoveLeft = 'slideMoveLeft',
//     slideMoveRight = 'slideMoveRight',
// }

// fb-collections
export type IZCollName = 'users' | 'zones' | 'beacons' | 'customers'
    | 'handshakeRequests' | 'notifis'
    | 'chats' | 'messages'
    | 'usersBookmarks' | 'zonesBookmarks' | 'usersHighlights' | 'zonesHighlights'
    | 'zonesFeeds';
