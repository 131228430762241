import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatBadgeModule } from '@angular/material/badge';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HelpersModule } from 'src/app/helpers/helpers.module';
import { IzIconModule } from 'src/app/helpers/iz-icon.module';



@NgModule({
  declarations: [FooterComponent],
  imports: [
    RouterModule,
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatBadgeModule,
    FlexLayoutModule,
    HelpersModule,
    IzIconModule,
  ],
  exports: [FooterComponent]
})
export class FooterModule { }
