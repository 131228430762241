import { InfoZone } from '../model/info-zone.model';
import { customAlphabet } from 'nanoid';
import * as moment from 'moment';
import firebase from 'firebase';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


/**
 * hdrs
 * @param token -
 */
export function hdrs(token: string): HttpHeaders {
    token = token || '';
    return new HttpHeaders(
        {
            Authorization: `Bearer ${token}`,
            auth: environment.admin.key,
        }
    );
}

export function generatePassword() {
    const length = 8;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let pwd = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
        pwd += charset.charAt(Math.floor(Math.random() * n));
    }
    return pwd;
}

/**
 * Get base64 from url
 *
 * @param url -
 */
export async function base64FromUrl(url: string): Promise<string> {
    const res = await fetch(url);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            resolve(reader.result as string);
        }, false);

        reader.onerror = () => {
            return reject(this);
        };
        reader.readAsDataURL(blob);
    });
}

/**
 * Get base64 from File
 *
 * @param file -
 */
export async function base64FromFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });
}


/**
 * v2TS
 * @param v -  null | Date | Moment | firebase.firestore.Timestamp
 */
export function v2TS(v: null | Date | moment.Moment | firebase.firestore.Timestamp): firebase.firestore.Timestamp {
    if (v instanceof Date) {
        return firebase.firestore.Timestamp.fromDate(v);
    } else if (moment.isMoment(v)) {
        return firebase.firestore.Timestamp.fromDate(moment(v).toDate());
    } else if (v instanceof firebase.firestore.Timestamp) {
        return v;
    } else {
        return null;
    }
}

/**
 * v2Date
 * @param v - null | Date | Moment | firebase.firestore.Timestamp
 */
export function v2Date(v: null | Date | moment.Moment | firebase.firestore.Timestamp, nll?: boolean): Date {
    if (v instanceof Date) {
        return v;
    } else if (v instanceof firebase.firestore.Timestamp) {
        return v.toDate();
    } else if (moment.isMoment(v)) {
        return moment(v).toDate();
    } else {
        return null;
    }
}



export function izid() {
    // return 'xxxxxx'.replace(/[xy]/g, (c) => {
    //     // tslint:disable-next-line: no-bitwise
    //     const r = Math.random() * 16 | 0;
    //     // tslint:disable-next-line: no-bitwise
    //     const v = c === 'x' ? r : (r & 0x3 | 0x8);
    //     return v.toString(16);
    // });
    const nanoid = customAlphabet('1234567890ABCDEFGHJKLMNOPQRSTUVWXYZ', 8);
    return nanoid();
}


/**
 * mergeIt
 * @param list1 -
 * @param list2 -
 */
export function mergeList<T extends InfoZone>(list1: Array<T>, list2: Array<T>): Array<T> {
    const ids = new Set(list1.map(d => d.uid));
    const merged = [...list1, ...list1.filter(d => !ids.has(d.uid))];
    return merged;
}

/**
 * uniqueIt
 * @param list1 -
 */
export function uniqueList<T extends InfoZone>(list1: Array<T>): Array<T> {
    const listUniques = list1.filter(Boolean); // remove emptys
    return listUniques
        .filter((v: T, i: number, a: T[]) => a.findIndex(t => (t.uid === v.uid)) === i);
}

/**
 * cloneString
 * @param a -
 */
export function cloneString<T>(a: T): T {
    return JSON.parse(JSON.stringify(a));
}


/**
 * For serializable deep clone, with Type Information
 * @param source -
 */
export function cloneDeep<T>(source: T): T {
    return Array.isArray(source)
        ? (source as Array<T>).map(item => cloneDeep(item))
        : source instanceof Date
            ? new Date(source.getTime())
            : source && typeof source === 'object'
                ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
                    Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop));
                    o[prop] = cloneDeep(source[prop]);
                    return o;
                }, Object.create(Object.getPrototypeOf(source)))
                : source;
}
