import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation.component';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';



@NgModule({
  declarations: [NavigationComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    RouterModule.forChild([
      { path: '', pathMatch: 'full', component: NavigationComponent, canActivate: [AuthGuardService] }
    ])
  ],
  // exports: [RouterModule],
  exports: [
    NavigationComponent,
    MatSidenavModule,
  ]
})
export class NavigationModule { }
