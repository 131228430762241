import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { FirestoreService } from './firestore.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private fb: FirestoreService,
    private auth: AuthenticationService,
    private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messages
      .subscribe(
        (messaging: any) => {
          messaging.onMessage = messaging.onMessage.bind(messaging);
          messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
        }
      );
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(token: string) {
    // we can change this function to request our backend service
    // this.auth.GetUserParent$()
    //   // .pipe(take(1))
    //   .subscribe(
    //     (u: any) => {
    //       // console.log('MessagingService: updateToken - user', u);
    //       if (u) {
    //         this.fb.upsert(`fcmTokens/${u.uid}`, { uid: u.uid, token });
    //       }
    //     });
    if (this.auth.userSocialParent) {
      this.fb.upsert(`fcmTokens/${this.auth.userSocialParent.uid}`, { uid: this.auth.userSocialParent.uid, token });
    }
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token: string) => {
        // console.log(token);
        this.updateToken(token);
      },
      (err: any) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        console.log('new message received. ', payload);
        this.currentMessage.next(payload);
      });
  }
}
