<div
  class="iz-ui-wrapper"
  fxLayout="column"
  fxLayoutAlign="start stretch"
>

  <ng-container *ngIf="!loading">


    <div class="inzo_home_top">

      <div class="logo">
        <img
          src="assets/logo.svg"
          alt="logo"
        >
      </div>

      <div class="title1">
        <span>INFO.ZONE</span>
      </div>

      <div class="title2">
        <span>Erfahre mehr über deine Umgebung.</span>
      </div>


    </div>


    <div
      class="inzo_home_box"
      *ngIf="isChrome || isPwa"
    >

      <div class="title1">
        <span>Melde dich mit einem deiner Profile an:</span>
      </div>

      <div class="buttons">
        <button
          class="btn_social"
          mat-flat-button
          (click)="SignFacebook()"
        >
          <img
            src="assets/images/facebook.svg"
            alt="facebook"
          >
          <span class="label">Login</span>
        </button>

        <!-- <button
          class="btn_social"
          mat-flat-button
          (click)="SignTwitter()"
        >
          <img
            src="assets/images/twitter.svg"
            alt="twitter"
          >
          <span class="label">Login</span>
        </button> -->

        <!-- <button
        class="btn_social"
        mat-flat-button
        (click)="signXing()"
      >
        <img
          src="assets/images/xing.svg"
          alt="xing"
        >
        <span class="label">Login</span>
      </button> -->

        <button
          class="btn_social"
          mat-flat-button
          (click)="signLinkedin()"
        >
          <img
            src="assets/images/linkedin.svg"
            alt="linkedin"
          >
          <span class="label">Login</span>
        </button>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="space-around center"
        class="w-100 iz-m-1"
      >
      </div>

      <div class="problem">
        <a
          routerLink="/faq"
          class="link"
        >Probleme bei der Anmeldung?</a>
      </div>

      <div class="title2">
        <span>Durch das Anmelden mit einem deiner Profile erklärst du dich mit unseren <a
            [routerLink]="['/agb']"
            class="link"
          >AGB</a> einverstanden. Erfahre in unserer
          <a
            [routerLink]="['/datenschutz']"
            class="link"
          >Datenschutzerklärung</a> und <a
            [routerLink]="['/datenschutz']"
            class="link"
          >Cookie-Richtlinien</a> mehr darüber, wie wir deine Daten verarbeiten.</span>
      </div>

    </div>

    <div
      class="inzo_home_box"
      *ngIf="!isChrome && !isPwa"
    >

      <div class="title1">
        <span>Leider kannst Du INFO.ZONE aktuell nur mit dem Chrome-Browser von google nutzen.</span>
      </div>

      <div class="title2">
        <span>- Lade den Chrome Browser herunter <a
            href="https://www.google.com/intl/de_de/chrome/"
            class="link"
          >>>>
            Chrome</a></span>
      </div>

      <div class="title2">
        <span>- Schreibe ein Feedback an <a
            href="https://www.apple.com/feedback/safari.html"
            class="link"
          >>>> Apple
            Safari</a>
          oder
          <a
            href="https://qsurvey.mozilla.com/s3/FirefoxInput/"
            class="link"
          >>>> Mozilla Firefox</a>
          und fordere, dass künftig INFO.ZONE und Progressive Web Apps unterstützt werden sollen
        </span>
      </div>

      <div class="title2">
        <span>- Folge unserem Projekt <a
            href="https://seu2.cleverreach.com/f/249237-245475/"
            class="link"
          >>>>
            Newsletter</a></span>
      </div>

      <div class="title2">
        <span>- Erstelle eine eigene INFO.ZONE <a
            href="https://shop.info.zone/"
            class="link"
          >>>> Shop</a></span>
      </div>



      <div class="title2">
        <span>Beste Grüße & get connected,<br>
          Dein INFO.ZONE-Team</span>
      </div>



    </div>

  </ng-container>

  <ngx-spinner
    bdColor="rgba(51, 51, 51, 0.25)"
    name="spContentLoad"
    zIndex="10"
    [fullScreen]="true"
    color="#094070"
  ></ngx-spinner>

</div>

<!-- <div
  style="display: none;"
  #xingload
></div> -->

<ngx-spinner color="#094070"></ngx-spinner>
