<mat-drawer-container class="drawer_container"
                      [ngClass]="{'iz-drawer-container-move': !(data.footerShow$|async)}">

  <mat-drawer #drawer
              mode="over"
              class="iz-drawer">
    <app-navigation [drawer]="drawer"></app-navigation>
  </mat-drawer>

  <mat-drawer-content>
    <!-- header -->
    <app-header [drawer]="drawer"></app-header>
    <!-- content -->
    <div class="iz-wrapper"
         [class.iz-wrapper-no-footer]="!(data.footerShow$|async)"
         [class.iz-wrapper-no-header]="!(data.headerShow$|async)"
         [class.iz-wrapper-no-fh]="!(data.footerShow$|async) &&!(data.headerShow$|async)"
         aria-label="wrap">
      <router-outlet></router-outlet>
    </div>
    <!-- footer -->
    <app-footer [drawer]="drawer"></app-footer>

  </mat-drawer-content>

</mat-drawer-container>
