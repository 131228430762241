import { Injectable } from "@angular/core";
import { HammerGestureConfig } from "@angular/platform-browser";

@Injectable()
export class IZHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
    pinch: { enable: false },
    rotate: { enable: false },
    threshold: 1,
    velocity: 0.1
  }
  buildHammer(element: HTMLElement) {
    return new Hammer(element, {
      touchAction: 'auto',
      // inputClass: Hammer. ? Hammer.PointerEventInput : Hammer.TouchInput,
      recognizers: [
        [Hammer.Swipe, {
          direction: Hammer.DIRECTION_ALL
        }],
        [Hammer.Pinch, {
          direction: Hammer.DIRECTION_NONE
        }],
        [Hammer.Rotate, {
          direction: Hammer.DIRECTION_NONE
        }]
      ]
    });
  }
}