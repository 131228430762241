import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JsonInterceptorService {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    request = request.clone({
      // headers: request.headers.set('Content-Type', 'application/json')
    });
    if (!request.url.includes('assets/images/icons')) {
      request = request.clone({ responseType: 'json' });
    }
    return next.handle(request);
  }

}
