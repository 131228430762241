import { IZAuthUser } from './auth.model';
import { InfoZone, InfoZoneFactory } from './info-zone.model';

export interface IZChat extends InfoZone {
    status: number;
    uidUser1: string;
    uidUser2: string;
    uids: string[];

    // locals
    user?: IZAuthUser;
    messagesCountNew?: number;
}

// export function chatMapTo(o: IZChat): IZChat {
//     delete o.type;
//     return o;
// }


export class ChatFactory extends InfoZoneFactory {
    static create(o: IZChat): IZChat {
        return {
            ...super.create(o),
            ...o
        };
    }

    static mapTo(o: IZChat): void {
        delete o.user;
        delete o.messagesCountNew;
        super.mapTo(o);
    }
}



export interface IZChatMessage extends InfoZone {
    status: number;
    message: string;
    uidUserFrom: string;
    uidUserTo: string;
}



export class ChatMessageFactory extends InfoZoneFactory {
    static create(o: IZChatMessage): IZChatMessage {
        return {
            ...super.create(o),
            ...o
        };
    }

    static mapTo(o: IZChatMessage): void {
        super.mapTo(o);
    }
}