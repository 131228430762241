import { Injectable } from '@angular/core';
import {
  Action,
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentChangeAction,
  DocumentSnapshotDoesNotExist,
  DocumentSnapshotExists,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from '@angular/fire/firestore';
import { combineLatest, defer, from, Observable, of } from 'rxjs';
import { expand, map, mergeMap, switchMap, take, takeWhile, tap } from 'rxjs/operators';

import firebase from 'firebase';
import { Query } from '@angular/fire/firestore';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  constructor(private afs: AngularFirestore) {
  }

  public uid(): string {
    return this.afs.createId();
  }

  public uidChat(uid1: string, uid2: string): string {
    // Check if user1’s id is less than user2's
    if (uid1 < uid2) {
      return uid1 + '$|$' + uid2;
    } else {
      return uid2 + '$|$' + uid1;
    }
  }

  get af() {
    return this.afs;
  }

  /// **************
  /// Get a Reference
  /// **************

  /// Firebase Server Timestamp
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  geo(la: number, lo: number): firebase.firestore.GeoPoint {
    return new firebase.firestore.GeoPoint(la, lo);
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.afs.collection<T>(ref, queryFn) : ref;
  }

  /// **************
  /// Get Data
  /// **************

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
  }

  doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref)
      .snapshotChanges()
      .pipe(
        map((doc: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<T>>) => {
          return doc.payload.data();
        }),
      );
  }

  // get once
  doc$$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref)
      .get()
      .pipe(
        map((doc: firebase.firestore.DocumentSnapshot<T>) => {
          return doc.data();
        }),
      );
  }

  // get once
  docLast$<T>(ref: CollectionPredicate<T>, uid: string): Observable<QueryDocumentSnapshot<any>> {
    return this.col(ref, (query: any) => query.where('uid', '==', uid))
      .snapshotChanges()
      .pipe(
        take(1),
        map((actions: DocumentChangeAction<T>[]) => {
          const docLast = actions[actions.length - 1];
          return docLast ? docLast.payload.doc : null;
        }),
      );
  }


  docLast$$<T>(ref: CollectionPredicate<T>, uid: string): Observable<QueryDocumentSnapshot<any>> {
    return this.col(ref, (query: any) => query.where('uid', '==', uid))
      .get()
      .pipe(
        map((res: QuerySnapshot<T>) => {
          const docLast = res?.docs[res.docs.length - 1] || null;
          return docLast;
        }),
      );
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?: Query): Observable<T[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((docs: DocumentChangeAction<T>[]) => {
          return docs.map((a: DocumentChangeAction<T>) => a.payload.doc.data());
        }),
      );
  }

  /// **************
  /// Write Data
  /// **************

  /// with Ids
  colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<any[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((actions: DocumentChangeAction<T>[]) => {
          return actions.map((action: DocumentChangeAction<T>) => {
            const data: any = action.payload.doc.data();
            // data.doc = action.payload.doc;
            data.type = action.type;
            const id = action.payload.doc.id;
            const uid = action.payload.doc.id;
            return { id, uid, ...data };
          });
        }),
      );
  }

  // colWithIdsDoc$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<any[]> {
  //   return this.col(ref, queryFn)
  //     .snapshotChanges()
  //     .pipe(
  //       map((actions: DocumentChangeAction<T>[]) => {
  //         return actions.map((a: DocumentChangeAction<T>) => {
  //           const data: any = a.payload.doc.data();
  //           data.doc = a.payload.doc;
  //           data.type = a.type;
  //           const id = a.payload.doc.id;
  //           const uid = a.payload.doc.id;
  //           return { id, uid, ...data };
  //         });
  //       }),
  //     );
  // }

  /// get once - with Ids
  colWithIds$$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<any[]> {
    return this.col(ref, queryFn)
      .get()
      .pipe(
        map((res: QuerySnapshot<T>) => {
          return res.docs.map((doc) => {
            const data: any = doc.data();
            // data.doc = doc;
            // data.type = a.tpye;
            const id = doc.id;
            const uid = doc.id;
            return { id, uid, ...data };
          });
        }),
      )
  }

  /// get once - with Ids and doc
  // colWithIdsDoc$$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<any[]> {
  //   return this.col(ref, queryFn)
  //     .get()
  //     .pipe(
  //       map((res: QuerySnapshot<T>) => {
  //         return res.docs.map((a) => {
  //           const data: any = a.data();
  //           data.doc = a;
  //           // data.type = a.tpye;
  //           const id = a.id;
  //           const uid = a.id;
  //           return { id, uid, ...data };
  //         });
  //       }),
  //     );
  // }


  set<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    delete data.doc; // remove doc-data
    delete data.pathp; // remove docparent-data
    const timestamp = this.timestamp;
    return this.doc(ref).set({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp,
    });
  }

  update<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    return this.doc(ref).update({
      ...data,
      updatedAt: this.timestamp,
    });
  }

  delete<T>(ref: DocPredicate<T>): Promise<void> {
    return this.doc(ref).delete();
  }

  add<T>(ref: CollectionPredicate<T>, data): Promise<firebase.firestore.DocumentReference> {
    const timestamp = this.timestamp;
    return this.col(ref).add({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp,
    });
  }

  geopoint(lat: number, lng: number): firebase.firestore.GeoPoint {
    return new firebase.firestore.GeoPoint(lat, lng);
  }

  /// If doc exists update, otherwise set
  async upsert<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    // console.log('upsert', data);
    const snap = await this.doc(ref)
      .snapshotChanges()
      .pipe(take(1))
      .toPromise() as Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<T>>;
    if (snap?.payload?.exists) {
      return await this.update(ref, data);
    } else {
      return await this.set(ref, data);
    }
    // return doc.then((snap: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<T>>) => {
    //   return snap.payload.exists ? this.update(ref, data) : this.set(ref, data);
    // });
  }


  /// **************
  /// Inspect Data
  /// **************

  inspectDoc(ref: DocPredicate<any>): void {
    const tick = new Date().getTime();
    this.doc(ref)
      .snapshotChanges()
      .pipe(
        take(1),
        tap((d: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<any>>) => {
          const tock = new Date().getTime() - tick;
          console.log(`Loaded Document in ${tock}ms`, d);
        }),
      )
      .subscribe();
  }

  inspectCol(ref: CollectionPredicate<any>): void {
    const tick = new Date().getTime();
    this.col(ref)
      .snapshotChanges()
      .pipe(
        take(1),
        tap((c: DocumentChangeAction<any>[]) => {
          const tock = new Date().getTime() - tick;
          console.log(`Loaded Collection in ${tock}ms`, c);
        }),
      )
      .subscribe();
  }

  /// **************
  /// Create and read doc references
  /// **************

  /// create a reference between two documents
  connect(host: DocPredicate<any>, key: string, doc: DocPredicate<any>) {
    return this.doc(host).update({ [key]: this.doc(doc).ref });
  }

  /// returns a documents references mapped to AngularFirestoreDocument
  docWithRefs$<T>(ref: DocPredicate<T>) {
    return this.doc$(ref).pipe(
      map((doc: T) => {
        for (const k of Object.keys(doc)) {
          if (doc[k] instanceof firebase.firestore.DocumentReference) {
            doc[k] = this.doc(doc[k].path);
          }
        }
        return doc;
      }),
    );
  }

  /// **************
  /// Atomic batch example
  /// **************

  /// Just an example, you will need to customize this method.
  atomic() {
    const batch = firebase.firestore().batch();
    /// add your operations here

    const itemDoc = firebase.firestore().doc('items/myCoolItem');
    const userDoc = firebase.firestore().doc('users/userId');

    const currentTime = this.timestamp;

    batch.update(itemDoc, { timestamp: currentTime });
    batch.update(userDoc, { timestamp: currentTime });

    /// commit operations
    return batch.commit();
  }

  /**
   * Delete a collection, in batches of batchSize. Note that this does
   * not recursively delete subcollections of documents in the collection
   * from: https://github.com/AngularFirebase/80-delete-firestore-collections/blob/master/src/app/firestore.service.ts
   */
  deleteCollection(path: string, batchSize: number): Observable<any> {
    const source = this.deleteBatch(path, batchSize);

    // expand will call deleteBatch recursively until the collection is deleted
    return source.pipe(
      expand(val => this.deleteBatch(path, batchSize)),
      takeWhile(val => val > 0),
    );
  }

  // Detetes documents as batched transaction
  private deleteBatch(path: string, batchSize: number): Observable<any> {
    const colRef = this.afs.collection(path, ref => ref.orderBy('__name__').limit(batchSize));

    return colRef.snapshotChanges().pipe(
      take(1),
      mergeMap((snapshot: DocumentChangeAction<{}>[]) => {
        // Delete documents in a batch
        const batch = this.afs.firestore.batch();
        snapshot.forEach(doc => {
          batch.delete(doc.payload.doc.ref);
        });

        return from(batch.commit()).pipe(map(() => snapshot.length));
      }),
    );
  }
}


export const leftJoin = (
  afs: AngularFirestore,
  field,
  collection,
  limit = 100
) => {
  return source =>
    defer(() => {
      // Operator state
      let collectionData;

      // Track total num of joined doc reads
      let totalJoins = 0;

      return source.pipe(
        switchMap(data => {
          // Clear mapping on each emitted val ;
          // Save the parent data state
          collectionData = data as any[];

          const reads$ = [];
          for (const doc of collectionData) {
            // Push doc read to Array
            if (doc[field]) {
              // Perform query on join key, with optional limit
              const q = ref => ref.where(field, '==', doc[field]).limit(limit);

              reads$.push(afs.collection(collection, q).valueChanges());
            } else {
              reads$.push(of([]));
            }
          }

          return combineLatest(reads$);
        }),
        map(joins => {
          return collectionData.map((v, i) => {
            totalJoins += joins[i].length;
            return { ...v, [collection]: joins[i] || null };
          });
        }),
        tap(final => {
          console.log(
            `Queried ${(final as any).length}, Joined ${totalJoins} docs`
          );
          totalJoins = 0;
        })
      );
    });
};
