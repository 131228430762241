import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bottom',
  templateUrl: './snack-bottom.component.html',
  styleUrls: ['./snack-bottom.component.scss']
})
export class SnackBottomComponent {

  constructor(
    public snackBarRef: MatSnackBarRef<SnackBottomComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public message: string
  ) { }

}
