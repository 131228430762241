import { Component, OnInit, Input } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fhFade } from 'src/app/helpers/animations';
import { HeaderUserLink } from 'src/app/model/header.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DaoUiService } from 'src/app/services/dao/dao-ui.service';
import { DataService } from 'src/app/services/data.service';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    fhFade,
  ]
})
export class HeaderComponent implements OnInit {

  @Input() drawer: MatDrawer;

  public onFadeSate = 'fadeinHeader';

  public headerSearchValueClicked = true;

  public chatsMessagesCountNew = 0;

  // public headerToggleMapAll = 0;

  constructor(
    public router: Router,
    public auth: AuthenticationService,
    public data: DataService,
    private dao: DaoUiService,
  ) { }

  ngOnInit() {
    console.log('HeaderComponent - ngOnInit');
    this.data.headerShow$
      .subscribe((b: boolean) => {
        if (b) {
          this.onFadeSate = 'fadeinHeader';
        } else {
          this.onFadeSate = 'fadeoutHeader';
        }
      });

      this.auth.GetUserParent$()
      .subscribe((u) => {
        console.log('FOOTER - ngOnInit - user', u);
        if (u) {
          this.dao.chatMessagesCountNewAll$()
            .pipe(untilDestroyed(this))
            .subscribe((count: number) => {
              console.log('FOOTER - chatsMessagesCountNew - ngOnInit - count', count);
              this.chatsMessagesCountNew = count;
            });
        }
      },
        (e) => {
          console.log(e);
        });
  }

  about() {
    this.router.navigate(['/about']);
  }

  profile() {
    this.router.navigate(['/user/profile']);
  }

  gohome() {
    console.log(this.router.url);
    this.router.navigate(['/zones']);
  }

  navigation() {
    console.log('this.drawer - ', this.drawer);
    this.drawer.toggle();
  }

  headerSearchValueChange() {
    this.headerSearchValueClicked = false;
    // if (!query) {
    //   this.headerSearchValueClicked = true;
    // }
    // console.log('headerSearchValueChange - query - ', query);
    // this.data.headerSearchValue$.next(query);
  }

  headerSearchValueChangeKK(query: string) {
    this.headerSearchValueClicked = true;
    console.log('headerSearchValueChangeKK - query - ', query);
    this.data.headerSearchValueKK$.next(query);
  }

  toogleFooter(what: number) {
    if (what === 0) {
      this.data.footerShowOff();
    } else {
      this.data.footerShowOn();
    }
  }

  toogleUserMenu(link: HeaderUserLink) {
    // this.data.headerToggleMapAll = what;
    console.log(link);
    switch (link) {
      case HeaderUserLink.ZonesMap:
        this.router.navigate(['/map']);
        break;
      case HeaderUserLink.ZonesAll:
        this.router.navigate(['/user/gesamtliste']);
        break;
      case HeaderUserLink.BookmarkUsers:
        this.router.navigate(['/user/bookmark/users']);
        break;
      case HeaderUserLink.Chats:
        this.router.navigate(['/user/chats']);
        break;
      case HeaderUserLink.ZonesMy:
        this.router.navigate(['/user/my-list']);
        break;
      case HeaderUserLink.BookmarkZones:
        this.router.navigate(['/user/bookmark/zones']);
        break;

    }
  }


}

