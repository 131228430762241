<ng-container>

  <div
    [@onFade]="onFadeSate"
    fxLayout="row wrap"
    fxLayoutAlign="center center"
    [ngClass]="auth.userAny ? 'iz-footer':'iz-footer-small'"
  >


    <div
      fxFlex="grow"
      fxLayoutAlign="space-around center"
      class="iz-pb-1"
    >

      <div
        class="iz-btn-round-wrapper inverse"
        *ngIf="auth.userAny"
      >
        <button
          mat-fab
          class="iz-btn-fabi"
          (click)="navigation()"
        >
          <mat-icon class="icon">dehaze</mat-icon>
        </button>
      </div>

      <div
        class="iz-btn-round-wrapper"
        *ngIf="auth.userSocialParent"
      >
        <button
          mat-fab
          class="iz-btn-fabi"
          (click)="router.navigate(['/user/profile'])"
        >
          <img
            class="image"
            alt="avatar"
            [src]="auth.userSocialParent?.userSocial?.photoURL"
          >
        </button>
      </div>

      <div
        class="iz-btn-round-wrapper"
        *ngIf="auth.userSocialParent"
      >
        <button
          class="iz-btn-fabi"
          mat-icon-button
          (click)="router.navigate(['/user/chats'])"
          [ngClass]="{'zero': chatsMessagesCountNew < 1}"
        >
          <mat-icon
            [svgIcon]="'iz-community'"
            class="icon"
          ></mat-icon>
        </button>
      </div>

      <div
        class="iz-btn-round-wrapper"
        *ngIf="auth.userSocialParent"
      >
        <button
          mat-fab
          class="iz-btn-fabi"
          (click)="router.navigate(['/user/my-list'])"
        >
          <mat-icon
            [svgIcon]="'iz-others'"
            class="iz-icon"
          ></mat-icon>
        </button>
      </div>

      <div
        class="iz-btn-round-wrapper"
        *ngIf="auth.userSocialParent"
      >
        <button
          mat-fab
          class="iz-btn-fabi"
          (click)="router.navigate(['/map'])"
        >
          <mat-icon
            [svgIcon]="'iz-location'"
            class="icon"
          ></mat-icon>
        </button>
      </div>

    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      class="info iz-pt-1"
    >
      <a
        (click)="shares.openUrl('https://datenschutz.info.zone/')"
        class="link"
      >Datenschutz</a>
      <span class="divide">|</span>
      <a
        (click)="shares.openUrl('https://agb.info.zone/')"
        class="link"
      >AGB</a>
      <span class="divide">|</span>
      <a
        (click)="shares.openUrl('https://impressum.info.zone/')"
        class="link"
      >Impressum</a>
      <span class="divide">|</span>
      <a
        (click)="shares.openUrl('https://kontakt.info.zone/')"
        class="link"
      >Kontakt</a>
      <span class="divide">|</span>
      <a
        (click)="shares.openUrl('https://shop.info.zone/')"
        class="link"
      >Shop</a>
    </div>

  </div>

</ng-container>
