import { trigger, state, style, transition, animate } from '@angular/animations';

export const AniSlideLeftRight
    = trigger('triggerSlideLeftRight', [
        state('slideStateRight', style({
            transform: 'translate(100%, 0)',
            opacity: 0,
            position: 'absolute',
        })),
        state('slideStateCenter', style({
            transform: 'translate(0,0)',
            opacity: 1,
            position: 'relative',
        })),
        state('slideStateLeft', style({
            transform: 'translate(-100%,0)',
            opacity: 0,
            position: 'absolute',
        })),
        state('slideMoveLeft', style({
            transform: 'translate(-100%, 0)',
            opacity: 0,
            position: 'absolute',
        })),
        state('slideMoveRight', style({
            transform: 'translate(100%,0)',
            opacity: 0,
            position: 'absolute',
        })),
        transition('*=>*', animate('300ms')),
    ]);

export const enlarge
    = trigger('onEnlarge', [
        state('enlarge', style({
            opacity: 1,
        })),
        state('reduce', style({
            opacity: 0,
        })),
        transition('* => *', animate(1000))
    ]);


export const fhFade
    = trigger('onFade', [
        state('fadeinFooter', style({
            opacity: 1,
            bottom: 0,
        })),
        state('fadeoutFooter', style({
            opacity: 0,
            bottom: '-6.5rem'
        })),
        state('fadeinHeader', style({
            opacity: 1,
            top: 0,
        })),
        state('fadeoutHeader', style({
            opacity: 0,
            top: '-6.5rem'
        })),
        transition('* => *', animate(300))
    ]);
