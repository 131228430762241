import { IZCategory } from './category.model';

export interface IZListUIFilter {
    filters?: string[];
}


export class IZZoneListFilter {
    constructor(
        public title: string,
        public filtered: boolean,
        public ztype1: boolean,
        public ztype2: boolean,
        public ztype3: boolean,
        public ztype4: boolean,
        public ztype5: boolean,
        public category: IZCategory,
        public keywords: string,
        public visibleFromd: Date,
        public visibleTilld: Date,
        public periodFromd: Date,
        public periodTilld: Date,
        public logo: boolean,
        public link: boolean,
        public membersCount: number,
        public filters: string[],
    ) {
    }
}

export function zoneListFilterNew(): IZZoneListFilter {
    return new IZZoneListFilter(
        'Filter',
        false,
        false,
        false,
        false,
        false,
        false,
        null,
        '',
        null,
        null,
        null,
        null,
        false,
        false,
        0,
        [],
    );
}


export interface IZZoneListMapSearch {
    
    filters?: string[];
    location?: string;
    periodFromd?: Date;
    periodTilld?: Date;
    geoNorthEast?: {
        lat: number,
        lng: number,
    };
    geoSouthWest?: {
        lat: number,
        lng: number,
    };


    geohashNorthWest?: string;
    geohashSouthWest?: string;
    geohashSouthEast?: string;
    geohashNorthEast?: string;


    geohashNorthWesti?: number;
    geohashSouthWesti?: number;

    geohashSouthEasti?: number;
    geohashNorthEasti?: number;

    radius?: number;

    radiusAll?: number;


    categorys?: string[];
    keywords?: string;
    zmode?: number;
    membersCount?: number;
}

export interface IZZoneListUIFilter extends IZListUIFilter {
    listType?: IZZoneListUIQuery,
    tabTopType?: TabTopType,
    keywords?: string;
}

export enum TabTopType {
    zones = 1,
    users = 2,
}

export enum IZZoneListUIQuery {
    active,
    archive,
}


