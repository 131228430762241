import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { last, take } from 'rxjs/operators';
import { HeaderUser, HeaderUserMenu } from '../model/header.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public headerSearchShow$ = new BehaviorSubject<boolean>(false);
  // public headerSearchValue$ = new BehaviorSubject<string>(null);
  public headerSearchValueKK$ = new BehaviorSubject<string>(null);

  public footerShow$ = new BehaviorSubject<boolean>(true);
  public headerShow$ = new BehaviorSubject<boolean>(true);

  public footerPreventHide: boolean;

  public headerUser$ = new BehaviorSubject<HeaderUserMenu>({
    menu: HeaderUser.None
  });

  private headerUserBackup: HeaderUserMenu;

  // constructor() { }

  headerSearchShowOn() {
    // this.headerSearchValue$.next(null);
    this.headerSearchValueKK$.next(null);
    this.headerSearchShow$.next(true);
  }

  headerSearchShowOff() {
    // this.headerSearchValue$.next(null);
    this.headerSearchValueKK$.next(null);
    this.headerSearchShow$.next(false);
  }

  headerUserToggle(headerUserMenu: HeaderUserMenu) {
    console.log('headerUserToggle - ', headerUserMenu);
    this.headerUser$.next(headerUserMenu);
  }

  async headerSearchUserToggle() {
    const searchShow = this.headerSearchShow$.getValue();
    if (searchShow) {
      this.headerUserToggle(this.headerUserBackup);
      this.headerSearchShowOff();
    } else {
      this.headerUserBackup = this.headerUser$.getValue();
      this.headerUserToggle({ menu: HeaderUser.None });
      this.headerSearchShowOn();
    }
  }

  // headerUserLinkToggle(headerUserLink: HeaderUserLink) {
  //   const hm = this.headerUser$.getValue();
  //   hm.link = headerUserLink;
  //   this.headerUser$.next(hm);
  // }


  // headerSearchShowChange(query: string) {
  //   this.headerSearchValue$.next(query);
  // }

  footerShowOn() {
    this.footerShow$.next(true);
  }

  footerShowOff() {
    if (!this.footerPreventHide) {
      this.footerShow$.next(false);
    }
  }

  headerShowOn() {
    this.headerShow$.next(true);
  }

  headerShowOff() {
    this.headerShow$.next(false);
  }

}

