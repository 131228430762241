import { IZKid, KidFactory } from './kid.model';

export interface IZFeed extends IZKid {
    pushNotifi?: boolean;
}


export class FeedFactory extends KidFactory {
    static create(o: Partial<IZFeed>): IZFeed {
        return {
            ...super.create(o),
            pushNotifi: o.pushNotifi || false,
        };
    }

    static mapTo(o: IZFeed): void {
        super.mapTo(o);
    }
}