import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';

import firebase from 'firebase';
import { MatSnackBar } from '@angular/material/snack-bar';
import { switchMap, map, take } from 'rxjs/operators';
import { FirestoreService } from './firestore.service';
import { IZAuth, IZAuthSocialParent, IZAuthSocial, SocialProvider, IZAuthCustomer, IZAuthAdmin, IZAuthUser, IZAuthLocked } from '../model/auth.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { FBFResponse } from '../model/info-zone.model';
import { sbWarnSett } from './constz';
import { Query } from '@angular/fire/firestore';
import { AuthLinkedInResp } from 'functions/src/models/auth.model';
import { IZZoneUserConn } from '../model/zone-user-conn';


@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  public afa: AngularFireAuth;

  // public userMe$: Observable<IZAuth>;
  public userAdmin: IZAuthAdmin;
  public userCustomer: IZAuthCustomer;

  // public userSocialParent$: Observable<IZAuthSocialParent>;
  public userSocialParent: IZAuthSocialParent;

  public userAny = false;

  constructor(
    private fireAuth: AngularFireAuth,
    private fb: FirestoreService,
    private sb: MatSnackBar,
    private httpClient: HttpClient,
  ) {
    this.afa = fireAuth;
    // console.log('AuthenticationService - initUser');
    this.initUser();
  }

  public GetUserAdmin$(): Observable<IZAuth> {
    return this.afa.authState
      .pipe(
        take(1),
        switchMap(user => {
          if (user) {
            // console.log('AuthenticationService - GetUser - ', user);
            return this.fb.doc$(`admins/${user.uid}`)
              .pipe(
                map((usera: IZAuth) => {
                  if (usera && usera.roles && usera.roles.includes('admin')) {
                    return usera;
                  } else {
                    return null;
                  }
                })
              );
          } else {
            // console.log('AuthenticationService - GetUser - user null');
            return of(null);
          }
        })
      );
  }

  public GetUserCustomer$(): Observable<IZAuth> {
    return this.afa.authState
      .pipe(
        take(1),
        switchMap(user => {
          if (user) {
            // console.log('AuthenticationService - GetUser - ', user);
            return this.fb.doc$(`customers/${user.uid}`)
              .pipe(
                map((usera: IZAuth) => {
                  if (usera && usera.roles && usera.roles.includes('customer')) {
                    return usera;
                  } else {
                    return null;
                  }
                })
              );
          } else {
            // console.log('AuthenticationService - GetUser - user null');
            return of(null);
          }
        })
      );
  }


  public GetUserParent$(): Observable<IZAuthSocialParent> {
    return this.afa.authState
      .pipe(
        switchMap(userAuthenticated => {
          if (userAuthenticated) {
            // console.log('AuthenticationService - GetUserParent$ - userAuthenticated', userAuthenticated);
            return this.GetUserParentByAUID$(userAuthenticated.uid);
          } else {
            // console.log('AuthenticationService - GetUserParent - userAuthenticated - user null');
            return of(null);
          }
        })
      );
  }


  public GetUserParentByAUID$(auid: string): Observable<IZAuthSocialParent> {
    return this.fb.doc$(`auths/${auid}`)
      .pipe(
        take(1),
        switchMap((userAuths: IZAuthSocial) => {
          if (userAuths) {
            // console.log('AuthenticationService - GetUserParentByAUID$ - auid', auid);
            // console.log('AuthenticationService - GetUserParentByAUID$ - userAuths', userAuths);
            return this.GetUserParentByUUID$(userAuths.uidParent);
          } else {
            // console.log('AuthenticationService - GetUserParentByAUID - userAuths - user null');
            return of(null);
          }
        })
      );
  }

  public GetUserParentByUUID$(uuid: string): Observable<IZAuthSocialParent> {
    return this.fb.doc$(`users/${uuid}`)
      .pipe(
        take(1),
        switchMap((userParent: IZAuthSocialParent) => {
          if (userParent) {
            return this.fb.doc$(`auths/${userParent.uidPriority}`)
              .pipe(
                take(1),
                map((userSocialPriority: IZAuthSocial) => {
                  // console.log('AuthenticationService - GetUserParentByUUID$ - uuid ', uuid);
                  // console.log('AuthenticationService - GetUserParentByUUID$ - userSocialPriority ', userSocialPriority);
                  if (userSocialPriority) { userParent.userSocial = userSocialPriority; }
                  // console.log('AuthenticationService - GetUserParentByUID - userParent ', userParent);
                  return userParent;
                })
              );
          } else {
            // console.log('AuthenticationService - GetUserParentByUUID - userParent - user null');
            return of(null);
          }
        })
      );
  }

  /**
   * GetUserParentByUUIDFull$
   * @param uuid -
   */
  public GetUserParentByUUIDFull$(uuid: string): Observable<IZAuthUser> {
    return this.fb.doc$(`users/${uuid}`)
      .pipe(
        take(1),
        switchMap((userParent: IZAuthSocialParent) => {
          if (userParent) {
            return this.fb.doc$(`auths/${userParent.uidPriority}`)
              .pipe(
                take(1),
                map((userSocialPriority: IZAuthSocial) => {
                  // console.log('AuthenticationService - GetUserParentByUUID - userSocialPrio ', userSocialPriority);
                  if (userSocialPriority) { userParent.userSocial = userSocialPriority; }
                  // console.log('AuthenticationService - GetUserParentByUID - userParent ', userParent);
                  return userParent;
                }),
              );
          } else {
            // console.log('AuthenticationService - GetUserParentByUUID - userParent - user null');
            return of(null);
          }
        }),
        switchMap((userParent: IZAuthSocialParent) => {
          // console.log(`LIST: switchMap -5- usersParent - `, userParent);
          if (!userParent) {
            return of(null);
          }
          // console.log(`auths get all by ${userParent.uid}`);
          return this.fb.colWithIds$$('auths', (ref: Query) => {
            return ref.where('uidParent', '==', userParent.uid);
          })
            .pipe(
              map((usersAuth: IZAuthSocial[]) => {
                console.log('userAuths', usersAuth);
                usersAuth.forEach((userAuth: IZAuthSocial) => {
                  // console.log('userAuth', userAuth);
                  // console.log('userParent', userParent);
                  switch (userAuth.provider) {
                    case SocialProvider.facebook:
                      userParent.ufacebook = userAuth;
                      break;
                    case SocialProvider.xing:
                      userParent.uxing = userAuth;
                      break;
                    case SocialProvider.twitter:
                      userParent.utwitter = userAuth;
                      break;
                    case SocialProvider.linkedin:
                      userParent.ulinkedin = userAuth;
                      break;
                    case SocialProvider.instagram:
                      userParent.uinstagram = userAuth;
                      break;
                    default:
                      break;
                  }
                  // return userParent;
                });
                return userParent;
              }),
            );
        }),
        // could be better?
        switchMap((userParent: IZAuthSocialParent) => {
          if (!userParent) {
            return this.fb.doc$<IZAuthCustomer>(`customers/${uuid}`)
              .pipe(
                take(1),
                map((userCustomer: IZAuthCustomer) => {
                  if (userCustomer) {
                    // const us = cloneDeep(userCustomer);
                    userCustomer.userSocial = { ...userCustomer };
                  }
                  return userCustomer;
                }),
              );
          } else {
            return of(userParent);
          }
        }),
        switchMap((userParent: IZAuthSocialParent) => {
          if (!userParent) {
            return this.fb.doc$<IZAuthAdmin>(`admins/${uuid}`)
              .pipe(
                take(1),
                map((userAdmin: IZAuthAdmin) => {
                  if (userAdmin) {
                    // const ua = cloneDeep(userAdmin);
                    userAdmin.userSocial = { ...userAdmin };
                  }
                  return userAdmin;
                }),
              );
          } else {
            return of(userParent);
          }
        }),
      );
  }


  /**
  * GetUserParentByPriorityFull$
  * @param uuid -
  */
  public GetUserParentByPriorityConn$(uuid: string, zoneUserConn: IZZoneUserConn): Observable<IZAuthUser> {

    return this.fb.doc$(`users/${uuid}`)
      .pipe(
        take(1),
        switchMap((userParent: IZAuthSocialParent) => {
          if (userParent) {
            const uidPriority = zoneUserConn.uidPriority || userParent.uidPriority;
            return this.fb.doc$(`auths/${uidPriority}`)
              .pipe(
                take(1),
                map((userSocialPriority: IZAuthSocial) => {
                  // console.log('AuthenticationService - GetUserParentByUUID - userSocialPrio ', userSocialPriority);
                  if (userSocialPriority) {
                    userParent.userSocial = userSocialPriority;
                    userParent.userSocial.description = zoneUserConn.description || userParent.userSocial.description;
                    userParent.userSocial.tags = zoneUserConn.tags || userParent.userSocial.tags;
                  }
                  // console.log('AuthenticationService - GetUserParentByUID - userParent ', userParent);
                  return userParent;
                }),
              );
          } else {
            // console.log('AuthenticationService - GetUserParentByUUID - userParent - user null');
            return of(null);
          }
        }),
        switchMap((userParent: IZAuthSocialParent) => {
          if (!userParent) {
            return of(null);
          }
          return this.fb.colWithIds$$('auths', (ref: Query) => {
            return ref.where('uidParent', '==', userParent.uid);
          })
            .pipe(
              map((usersAuth: IZAuthSocial[]) => {
                console.log('userAuths', usersAuth);
                usersAuth.forEach((userAuth: IZAuthSocial) => {
                  switch (userAuth.provider) {
                    case SocialProvider.facebook:
                      userParent.ufacebook = zoneUserConn.uidFacebook ? userAuth : null;
                      break;
                    case SocialProvider.xing:
                      userParent.uxing = zoneUserConn.uidXing ? userAuth : null;
                      break;
                    case SocialProvider.twitter:
                      userParent.utwitter = zoneUserConn.uidTwitter ? userAuth : null;
                      break;
                    case SocialProvider.linkedin:
                      userParent.ulinkedin = zoneUserConn.uidLinkedin ? userAuth : null;
                      break;
                    default:
                      break;
                  }
                  // return userParent;
                });
                return userParent;
              }),
            );
        }),
      );
  }

  public initUser() {
    this.GetUserAdmin$()
      .subscribe((userAdmin: IZAuthAdmin) => {
        if (userAdmin) {
          this.userAdmin = userAdmin;
          this.userAny = true;
        } else {
          this.userAdmin = null;
        }
      });

    this.GetUserCustomer$()
      .subscribe((userCustomer: IZAuthCustomer) => {
        if (userCustomer) {
          this.userCustomer = userCustomer;
          this.userAny = true;
        } else {
          this.userCustomer = null;
        }
      });


    // this.userSocialParent$ = this.GetUserParent$();
    this.GetUserParent$()
      .subscribe((userSocialParent: IZAuthSocialParent) => {
        if (userSocialParent) {
          this.userSocialParent = userSocialParent;
          this.userAny = true;
        } else {
          this.userSocialParent = null;
        }
      });
  }

  /* Sign up */
  SignUp(email: string, password: string) {
    this.fireAuth
      .createUserWithEmailAndPassword(email, password)
      .then(res => {
        console.log('Successfully signed up!', res);
      })
      .catch(error => {
        console.log('Something is wrong:', error.message);
      });
  }

  /* Sign in */
  async SignIn(email: string, password: string): Promise<firebase.auth.UserCredential> {
    try {
      const u = await this.fireAuth.signInWithEmailAndPassword(email, password);
      console.log('Successfully signed in!');
      this.initUser();
      return u;
    } catch (error) {
      console.log(error);
      this.sb.open(`Fehler: ${error.message}`, 'x', sbWarnSett);
      return null;
    }
  }

  async SignFacebook(scopes: string[] = null): Promise<firebase.auth.UserCredential> {
    const provider = new firebase.auth.FacebookAuthProvider();
    if (scopes) {
      scopes.forEach(scope => {
        console.log('FACEBOOK - addScope - ', scope);
        provider.addScope(scope);
      });
    }
    try {
      const u = await this.fireAuth.signInWithPopup(provider);
      console.log('FACEBOOK - you have been successfully logged in!');
      return u;
    } catch (error) {
      console.log(error);
      this.sb.open(`FACEBOOK-Fehler: ${error.message}`, 'x', sbWarnSett);
    }
  }

  async SignTwitter() {
    const provider = new firebase.auth.TwitterAuthProvider();
    try {
      const u = await this.fireAuth.signInWithPopup(provider);
      console.log('Twiter - you have been successfully logged in!');
      return u;
    } catch (error) {
      console.log(error);
      this.sb.open(`TWITTER-Fehler: ${error.message}`, 'x', sbWarnSett);
    }
  }

  async SignXing(token: string): Promise<firebase.auth.UserCredential> {
    try {
      const u: firebase.auth.UserCredential = await this.fireAuth.signInWithCustomToken(token);
      console.log('Xing - you have been successfully logged in!');
      return u;
    } catch (error) {
      console.log(error);
      this.sb.open(`XING-Fehler: ${error.message}`, 'x', sbWarnSett);
    }
  }


  async SignLinkedin(token: string): Promise<firebase.auth.UserCredential> {
    try {
      const u: firebase.auth.UserCredential = await this.fireAuth.signInWithCustomToken(token);
      console.log('linkedin - you have been successfully logged in!');
      return u;
    } catch (error) {
      console.log(error);
      this.sb.open(`XING-Fehler: ${error.message}`, 'x', sbWarnSett);
    }
  }

  async SignToken(token: string) {
    return this.fireAuth.signInWithCustomToken(token);
  }

  async GetToken() {
    return firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
  }

  async GetAuthUID() {
    return this.afa.currentUser;
  }



  /**
   * loginFacebook
   */
  async loginFacebook(userAuthSocialParentLink?: IZAuthSocialParent): Promise<IZAuthSocialParent> {
    const scopes = ['user_link'];
    const userCredential: firebase.auth.UserCredential = await this.SignFacebook(scopes);
    console.log('SignFacebook userCredential', userCredential);
    if (!userCredential?.user) { return; }

    // if user locked
    if (await this.isUserLocked(userCredential.user.providerData[0].uid)) {
      console.warn('User is locked', userCredential);
      return null;
    }

    let userAuthSocial: IZAuthSocial = await this.fb.doc$(`auths/${userCredential.user.uid}`)
      .pipe(take(1))
      .toPromise() as IZAuthSocial;

    if (userAuthSocialParentLink && userAuthSocial) {
      return null;
    }

    let userAuthSocialParent: IZAuthSocialParent = userAuthSocialParentLink;

    const photoURL = (userCredential?.additionalUserInfo?.profile as any)?.picture?.data?.url
      ? (userCredential?.additionalUserInfo?.profile as any)?.picture?.data?.url
      : userCredential.user.photoURL + '?height=300&width=300';
    const link = (userCredential?.additionalUserInfo?.profile as any)?.link ? (userCredential?.additionalUserInfo?.profile as any)?.link : '';

    if (!userAuthSocial) {
      if (!userAuthSocialParent) {
        userAuthSocialParent = {
          uid: this.fb.uid(),
          createdBy: userCredential.user.uid,
          uidPriority: userCredential.user.uid,
          status: 50
        };
      }

      userAuthSocial = {
        uid: userCredential.user.uid,
        createdBy: userCredential.user.uid,
        uidProvider: userCredential.user.providerData[0].uid,
        uidParent: userAuthSocialParent.uid,
        email: userCredential.user.email,
        displayName: userCredential.user.displayName,
        photoURL,
        provider: SocialProvider.facebook,
        facebook: userCredential.user.uid,
        visible: true,
        link,
        tags: [],
        description: '',
      };

    } else {
      if (!userAuthSocialParent) {
        const userAuthSocialParentPath = `users/${userAuthSocial.uidParent}`;
        userAuthSocialParent = await this.fb.doc$(userAuthSocialParentPath)
          .pipe(take(1))
          .toPromise() as IZAuthSocialParent;
        if (!userAuthSocialParent) {
          throw new Error(`userAuthSocialParent is not found by <${userAuthSocialParentPath}>`);
        }
      }
      userAuthSocial.uidProvider = userCredential.user.providerData[0].uid;
      userAuthSocial.email = userCredential.user.email;
      userAuthSocial.displayName = userCredential.user.displayName;
      userAuthSocial.photoURL = photoURL;
      userAuthSocial.link = link;
    }

    if (userAuthSocialParentLink) {
      userAuthSocialParent.uidPriority = userAuthSocial.uid;
    }

    // filters
    this.userFilters(userAuthSocialParent, userAuthSocial);

    console.log('SignFacebook userAuthSocial', userAuthSocial);
    console.log('SignFacebook userAuthSocialParent', userAuthSocialParent);
    await this.fb.upsert(`auths/${userAuthSocial.uid}`, userAuthSocial);
    await this.fb.upsert(`users/${userAuthSocialParent.uid}`, userAuthSocialParent);
    userAuthSocialParent.userSocial = userAuthSocial;
    return userAuthSocialParent;
  }

  /**
   * loginTwitter
   */
  async loginTwitter(userAuthSocialParentLink?: IZAuthSocialParent): Promise<IZAuthSocialParent> {
    const userCredential: firebase.auth.UserCredential = await this.SignTwitter();
    console.log('SignTwiiter userCredential', userCredential);
    if (!userCredential?.user) { return; }

    const userCredentialProfile = userCredential.additionalUserInfo.profile as any;

    // if user locked
    if (await this.isUserLocked(userCredentialProfile.id)) {
      console.warn('User is locked', userCredential);
      return null;
    }


    let userAuthSocial: IZAuthSocial = await this.fb.doc$(`auths/${userCredential.user.uid}`)
      .pipe(take(1))
      .toPromise() as IZAuthSocial;
    if (userAuthSocialParentLink && userAuthSocial) {
      return null;
    }
    let userAuthSocialParent: IZAuthSocialParent = userAuthSocialParentLink;


    console.log('SignTwiiter userCredentialProfile', userCredentialProfile);
    const photoURL = userCredentialProfile.profile_image_url ? userCredentialProfile.profile_image_url.replace('_normal', '') : '';
    const link = 'https://twitter.com/' + (userCredential.additionalUserInfo.username ? userCredential.additionalUserInfo.username : '');

    if (!userAuthSocial) {
      if (!userAuthSocialParent) {
        userAuthSocialParent = {
          uid: this.fb.uid(),
          createdBy: userCredential.user.uid,
          uidPriority: userCredential.user.uid,
          status: 50
        };
      }
      userAuthSocial = {
        uid: userCredential.user.uid,
        createdBy: userCredential.user.uid,
        uidProvider: userCredentialProfile.id,
        uidParent: userAuthSocialParent.uid,
        email: userCredentialProfile.email,
        displayName: userCredentialProfile.name,
        photoURL,
        provider: SocialProvider.twitter,
        twitter: userCredential.user.uid,
        // info2: userCredentialProfile.screen_name,
        visible: true,
        link,
        tags: [],
        description: '',
      };

    } else {
      if (!userAuthSocialParent) {
        const userAuthSocialParentPath = `users/${userAuthSocial.uidParent}`;
        userAuthSocialParent = await this.fb.doc$(userAuthSocialParentPath)
          .pipe(take(1))
          .toPromise() as IZAuthSocialParent;
        if (!userAuthSocialParent) {
          throw new Error(`userAuthSocialParent is not found by <${userAuthSocialParentPath}>`);
        }
      }
      userAuthSocial.uidProvider = userCredentialProfile.id;
      userAuthSocial.email = userCredentialProfile.email;
      userAuthSocial.displayName = userCredentialProfile.name;
      userAuthSocial.photoURL = photoURL;
      userAuthSocial.link = link;
    }
    console.log('SignTwiiter userAuthSocial', userAuthSocial);
    console.log('SignTwiiter userAuthSocialParent', userAuthSocialParent);
    if (userAuthSocialParentLink) {
      userAuthSocialParent.uidPriority = userAuthSocial.uid;
    }

    // filters
    this.userFilters(userAuthSocialParent, userAuthSocial);

    await this.fb.upsert(`auths/${userAuthSocial.uid}`, userAuthSocial);
    await this.fb.upsert(`users/${userAuthSocialParent.uid}`, userAuthSocialParent);
    userAuthSocialParent.userSocial = userAuthSocial;
    return userAuthSocialParent;
  }


  /**
   * loginXing
   * @param userXing -
   */
  async loginXing(userXing: any, userAuthSocialParentLink?: IZAuthSocialParent): Promise<IZAuthSocialParent> {

    // get some data from extern user-xing-object
    console.log('signXing - userXing - ', userXing);
    // const userXing = userXing;
    const userXingUID = userXing.id.substring(0, userXing.id.indexOf('_'));
    const link = userXing.permalink ? userXing.permalink : '';
    const photoURL = userXing.photo_urls.maxi_thumb ? userXing.photo_urls.maxi_thumb.replace('70x93', '256x256') : '';

    // User - Auth
    const userAuth: IZAuth = {
      uid: userXingUID,
      createdBy: userXing.id,
      email: userXing.active_email,
      displayName: userXing.display_name,
      photoURL,
      provider: SocialProvider.xing,
      visible: true,
    };

    console.log('signXing - userAuth - ', userAuth);
    let authResponse: FBFResponse = null;
    try {
      authResponse = await this.httpClient.post(environment.token.url + environment.token.xing, userAuth).toPromise() as FBFResponse;
      console.log('signXing - authResponse - ', authResponse);
    } catch (error) {
      console.error(error);
      throw new Error(`XING: URL is ungültig: ${environment.token.url + environment.token.xing}`);
    }
    if (authResponse?.status > 0) {
      throw new Error(`XING:  ${authResponse.error}`);
    }

    console.log('before SignXing - userCredential');
    const userCredential: firebase.auth.UserCredential = await this.SignXing(authResponse.token);
    console.log('after SignXing - userCredential');


    // if user locked
    if (await this.isUserLocked(userXing.id)) {
      console.warn('User is locked', userCredential);
      return null;
    }

    // User - Social
    let userAuthSocial: IZAuthSocial = await this.fb.doc$(`auths/${userCredential.user.uid}`)
      .pipe(take(1))
      .toPromise() as IZAuthSocial;
    if (userAuthSocialParentLink && userAuthSocial) {
      return null;
    }
    let userAuthSocialParent: IZAuthSocialParent = userAuthSocialParentLink;

    if (!userAuthSocial) {
      if (!userAuthSocialParent) {
        userAuthSocialParent = {
          uid: this.fb.uid(),
          createdBy: userCredential.user.uid,
          uidPriority: userCredential.user.uid,
          status: 50
        };
      }
      userAuthSocial = {
        uid: userXingUID,
        createdBy: userXing.id,
        uidProvider: userXing.id,
        uidParent: userAuthSocialParent.uid,
        email: userXing.active_email,
        displayName: userXing.display_name,
        photoURL,
        provider: SocialProvider.xing,
        xing: userXingUID,
        visible: true,
        link,
        tags: [],
        description: '',
      };

    } else {
      if (!userAuthSocialParent) {
        const userAuthSocialParentPath = `users/${userAuthSocial.uidParent}`;
        userAuthSocialParent = await this.fb.doc$(userAuthSocialParentPath)
          .pipe(take(1))
          .toPromise() as IZAuthSocialParent;
        if (!userAuthSocialParent) {
          throw new Error(`userAuthSocialParent is not found by <${userAuthSocialParentPath}>`);
        }
      }
      userAuthSocial.uidProvider = userXing.id;
      userAuthSocial.email = userXing.active_email;
      userAuthSocial.displayName = userXing.display_name;
      userAuthSocial.photoURL = photoURL;
      userAuthSocial.link = link;
    }

    if (userAuthSocialParentLink) {
      userAuthSocialParent.uidPriority = userAuthSocial.uid;
    }

    // filters
    this.userFilters(userAuthSocialParent, userAuthSocial);

    console.log('SignXing userAuthSocial', userAuthSocial);
    console.log('SignXing userAuthSocialParent', userAuthSocialParent);
    await this.fb.upsert(`auths/${userAuthSocial.uid}`, userAuthSocial);
    await this.fb.upsert(`users/${userAuthSocialParent.uid}`, userAuthSocialParent);
    userAuthSocialParent.userSocial = userAuthSocial;
    return userAuthSocialParent;
  }


  /**
   * loginLinkedin
   * @param userLinkedin -
   */
  async loginLinkedin(userLinkedin: AuthLinkedInResp, userAuthSocialParentLink?: IZAuthSocialParent): Promise<IZAuthSocialParent> {
    // User - Auth
    // userLinkedin
    console.log('signLinkedin - userLinkedin - ', userLinkedin);
    let authResponse: FBFResponse = null;
    try {
      authResponse = await this.httpClient.post(environment.token.url + environment.token.linkedin, userLinkedin).toPromise() as FBFResponse;
      console.log('signLinkedin - authResponse - ', authResponse);
    } catch (error) {
      console.error(error);
      throw new Error(`Linkedin: URL is ungültig: ${environment.token.url + environment.token.linkedin}`);
    }
    if (authResponse?.status > 0) {
      throw new Error(`Linkedin:  ${authResponse.error}`);
    }
    console.log('before SignLinkedin - userCredential');
    const userCredential: firebase.auth.UserCredential = await this.SignLinkedin(authResponse.token);
    console.log('after SignLinkedin - userCredential');

    // if user locked
    if (await this.isUserLocked(userCredential.user.uid)) {
      console.warn('User is locked', userCredential);
      return null;
    }


    // User - Social
    let userAuthSocial: IZAuthSocial = await this.fb.doc$(`auths/${userCredential.user.uid}`)
      .pipe(take(1))
      .toPromise() as IZAuthSocial;
    if (userAuthSocialParentLink && userAuthSocial) {
      return null;
    }
    let userAuthSocialParent: IZAuthSocialParent = userAuthSocialParentLink;

    const link = 'https://www.linkedin.com/search/results/all/?keywords=' + (userCredential.user.displayName ? userCredential.user.displayName : '');

    if (!userAuthSocial) {
      if (!userAuthSocialParent) {
        userAuthSocialParent = {
          uid: this.fb.uid(),
          createdBy: userCredential.user.uid,
          uidPriority: userCredential.user.uid,
          status: 50
        };
      }

      userAuthSocial = {
        uid: userCredential.user.uid,
        createdBy: userCredential.user.uid,
        uidProvider: userCredential.user.uid,
        uidParent: userAuthSocialParent.uid,
        email: userCredential.user.email,
        displayName: userCredential.user.displayName,
        photoURL: userCredential.user.photoURL,
        provider: SocialProvider.linkedin,
        linkedin: userCredential.user.uid,
        visible: true,
        link,
        tags: [],
        description: '',
      };

    } else {
      if (!userAuthSocialParent) {
        const userAuthSocialParentPath = `users/${userAuthSocial.uidParent}`;
        userAuthSocialParent = await this.fb.doc$(userAuthSocialParentPath)
          .pipe(take(1))
          .toPromise() as IZAuthSocialParent;
        if (!userAuthSocialParent) {
          throw new Error(`userAuthSocialParent is not found by <${userAuthSocialParentPath}>`);
        }
      }
      userAuthSocial.uidProvider = userCredential.user.uid;
      userAuthSocial.email = userCredential.user.email;
      userAuthSocial.displayName = userCredential.user.displayName;
      userAuthSocial.photoURL = userCredential.user.photoURL;
      userAuthSocial.link = link;
    }

    if (userAuthSocialParentLink) {
      userAuthSocialParent.uidPriority = userAuthSocial.uid;
    }

    // filters
    this.userFilters(userAuthSocialParent, userAuthSocial);

    console.log('loginLinkedin userAuthSocial', userAuthSocial);
    console.log('loginLinkedin userAuthSocialParent', userAuthSocialParent);
    await this.fb.upsert(`auths/${userAuthSocial.uid}`, userAuthSocial);
    await this.fb.upsert(`users/${userAuthSocialParent.uid}`, userAuthSocialParent);
    userAuthSocialParent.userSocial = userAuthSocial;
    return userAuthSocialParent;
  }

  /**
   * deactivateParentOhter
   * @param uidParent -
   */
  // deactivateParentOhter(uidParent: string) {
  //   this.fb.upsert(`users/${uidParent}`, { status: 0 });
  // }


  /**
   * vilibleUpdate
   * @param userSocial -
   */
  async vilibleUpdate(userSocial: IZAuthSocial) {
    this.fb.upsert(`auths/${userSocial.uid}`, { visible: !userSocial.visible });
  }

  /**
   * isUserLocked
   * @param userCredential -
   * @param uidProvider -
   */
  private async isUserLocked(uidProvider: string): Promise<boolean> {
    // if (userCredential.additionalUserInfo.isNewUser) {
    const userAuthLocked = await this.fb.doc$(`authsLocked/${uidProvider}`)
      .pipe(take(1))
      .toPromise() as IZAuthLocked;
    if (userAuthLocked) {
      return true;
    }
    // }
    return false;
  }

  /**
   * priorityUpdate
   * @param userSocial -
   */
  async priorityUpdate(userSocialParent: IZAuthSocialParent, userSocial: IZAuthSocial) {
    this.userFilters(userSocialParent, userSocial);
    await this.fb.upsert(`users/${userSocialParent.uid}`, {
      uidPriority: userSocial.uid,
      filters: userSocialParent.filters,
      tags: userSocial.tags,
      description: userSocial.description,
    });
    await this.fb.upsert(`auths/${userSocial}`, { visible: true });
  }


  /* Sign out */
  async SignOut() {
    // this.userSocialParent$ = null;
    this.userSocialParent = null;
    this.userAdmin = null;
    this.userCustomer = null;
    this.userAny = false;
    return this.fireAuth.signOut();
  }

  /**
   * saveDescription
   * @param description -
   */
  async saveDescription(userSocial: IZAuthSocial): Promise<void> {
    this.userFilters(this.userSocialParent, this.userSocialParent.userSocial);
    this.userSocialParent.description = userSocial.description;
    this.userSocialParent.userSocial = userSocial;
    await this.fb.upsert(`auths/${this.userSocialParent.userSocial.uid}`, {
      description: userSocial.description
    });
    return this.fb.upsert(`users/${this.userSocialParent.uid}`, {
      filters: this.userSocialParent.filters,
      description: userSocial.description
    });
  }


  /**
   * saveTags
   * @param tags -
   */
  async saveTags(userSocial: IZAuthSocial): Promise<void> {
    this.userFilters(this.userSocialParent, userSocial);
    this.userSocialParent.tags = userSocial.tags;
    this.userSocialParent.userSocial = userSocial;
    await this.fb.upsert(`auths/${this.userSocialParent.userSocial.uid}`, {
      tags: userSocial.tags
    });
    return this.fb.upsert(`users/${this.userSocialParent.uid}`, {
      filters: this.userSocialParent.filters,
      tags: userSocial.tags
    });
  }

  /**
   * saveTagsBySocial
   * @param tags -
   */
  //  async saveTagsBySocial(tags: string[], userSocial: IZAuthSocial): Promise<void> {
  //   this.userFilters(this.userSocialParent, userSocial);
  //   await this.fb.upsert(`auths/${userSocial.uid}`, {
  //     tags
  //   });
  //   return this.fb.upsert(`users/${this.userSocialParent.uid}`, {
  //     filters: this.userSocialParent.filters,
  //   });
  // }

  /**
   * savePushNotifi
   * @param pushNotifi -
   */
  async savePushNotifi(pushNotifi: boolean): Promise<void> {
    return this.fb.upsert(`users/${this.userSocialParent.uid}`, { pushNotifi });
  }

  /**
   * savePushNotifiChat
   * @param pushNotifiChat -
   */
  async savePushNotifiChat(pushNotifiChat: boolean): Promise<void> {
    return this.fb.upsert(`users/${this.userSocialParent.uid}`, { pushNotifiChat });
  }

  /**
   * saveLocation
   * @param lat -
   * @param lng -
   */
  async saveLocation(lat: number, lng: number, zoom = 20): Promise<void> {
    console.log('saveLocation', lat, lng, zoom);
    this.fb.upsert(`users/${this.userSocialParent.uid}`, {
      lat,
      lng,
      zoom,
    });
  }

  // userSocialsCount(): number {
  //   let count = 0;
  //   if (this.userSocialParent.ufacebook) count++;
  //   if (this.userSocialParent.uxing) count++;
  //   if (this.userSocialParent.utwitter) count++;
  //   if (this.userSocialParent.ulinkedin) count++;
  //   return count;
  // }


  /**
   * userFilters
   * @param userAuthSocialParent -
   * @param userAuthSocial -
   */
  private userFilters(userAuthSocialParent: IZAuthSocialParent, userAuthSocial: IZAuthSocial) {
    // filters
    userAuthSocialParent.filters = [];
    if (userAuthSocial.tags?.length) {
      const tags = userAuthSocial.tags.map((tag: string) => {
        return tag.toLowerCase();
      });
      userAuthSocialParent.filters.push(...tags)
    }
    if (userAuthSocial.description) {
      const descriptions = userAuthSocial.description.split(' ');
      userAuthSocialParent.filters.push(...descriptions);
    }
    if (userAuthSocial.displayName) {
      const displayNames = userAuthSocial.displayName.split(' ').map((tag: string) => {
        return tag.toLowerCase();
      });
      userAuthSocialParent.filters.push(...displayNames);
    }
  }


}


