import { InfoZone } from './info-zone.model';
import { IZZone } from './zone.model';

import { IZKid } from './kid.model';
import { IZBookmark } from './bookmark.model';

export interface IZAuth extends InfoZone {
    email: string;
    username?: string;

    roles?: string[];
    provider?: SocialProvider;

    photoURL?: string;

    displayName?: string;
    visible?: boolean;
    // utype?: string;
}

export interface IZAuthAdmin extends IZAuth {
    utype?: string;
    status?: number;
    uidPriority?: string;
    userSocial?: IZAuthSocial;
    // filters?: string[];
}

export interface IZAuthCustomer extends IZAuth {
    utype?: string;
    status?: number;
    uidPriority?: string;
    userSocial?: IZAuthSocial;
    // filters?: string[];
}

export interface IZAuthSocial extends IZAuth {
    facebook?: string;
    twitter?: string;
    linkedin?: string;
    xing?: string;

    uidParent?: string;
    uidProvider?: string;

    link?: string;

    description?: string;
    tags?: string[];
}


export interface IZAuthLocked extends IZAuth {
    uidProvider?: string;
    provider?: SocialProvider;
}


export interface IZAuthSocialParent extends InfoZone {
    userSocial?: IZAuthSocial;
    status: number;
    uidPriority?: string;

    ufacebook?: IZAuthSocial;
    uxing?: IZAuthSocial;
    utwitter?: IZAuthSocial;
    ulinkedin?: IZAuthSocial;
    uinstagram?: IZAuthSocial;

    description?: string;
    tags?: string[];
    pushNotifi?: boolean;
    pushNotifiChat?: boolean;

    utype?: string;

    // ignores by save
    bookmark?: IZBookmark;
    messagesCountNew?: number;
    highlight?: IZKid;

    lat?: number;
    lng?: number;
    zoom?: number;

    // filters?: string[];
}

export type IZAuthUser = IZAuthSocialParent | IZAuthAdmin | IZAuthCustomer;

export enum SocialProvider {
    facebook = 'facebook',
    xing = 'xing',
    twitter = 'twitter',
    linkedin = 'linkedin',
    instagram = 'instagram',
}


export type IZBookmarkPre = IZZone | IZAuthSocialParent;
