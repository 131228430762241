import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DotDotDotPipe, HSRtypePipe, NtypePipe, ZtypePipe } from './iztypes.pipe';
import { DeDateFormatDirective } from './de-date-format.directive';



@NgModule({
  declarations: [
    ZtypePipe,
    NtypePipe,
    HSRtypePipe,
    DotDotDotPipe,
    DeDateFormatDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ZtypePipe,
    NtypePipe,
    HSRtypePipe,
    DotDotDotPipe,
    DeDateFormatDirective,
  ]
})
export class HelpersModule { }
