import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatIconModule,
  ]
})

export class IzIconModule {
  private pIcons = 'assets/images/icons';
  constructor(
    private domSanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry
  ) {
    this.matIconRegistry
      .addSvgIcon('iz-community', this.setPath(`${this.pIcons}/community.svg`))
      .addSvgIcon('iz-event', this.setPath(`${this.pIcons}/event.svg`))
      .addSvgIcon('iz-information', this.setPath(`${this.pIcons}/information.svg`))
      .addSvgIcon('iz-location', this.setPath(`${this.pIcons}/location.svg`))
      .addSvgIcon('iz-money', this.setPath(`${this.pIcons}/money.svg`))
      .addSvgIcon('iz-others', this.setPath(`${this.pIcons}/others.svg`))
      .addSvgIcon('iz-handshake', this.setPath(`${this.pIcons}/handshake.svg`))
      .addSvgIcon('iz-bluetooth', this.setPath(`${this.pIcons}/bluetooth.svg`))
      .addSvgIcon('iz-power-settings-new', this.setPath(`${this.pIcons}/power-settings-new.svg`))
      .addSvgIcon('iz-lock-open', this.setPath(`${this.pIcons}/lock-open.svg`))
      .addSvgIcon('iz-lock-close', this.setPath(`${this.pIcons}/lock-close.svg`))
      .addSvgIcon('iz-marker-dot', this.setPath(`${this.pIcons}/marker-dot.svg`))
      .addSvgIcon('iz-zone-white', this.setPath(`${this.pIcons}/zone-white.svg`))
      .addSvgIcon('iz-zone-white-crossed', this.setPath(`${this.pIcons}/zone-white-crossed.svg`))

      ;
  }
  private setPath(url: string): SafeResourceUrl {
    // console.log('IzIconModule - ', url);
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
