import { Component, OnInit } from '@angular/core';
import { MessagingService } from './services/messaging.service';
import { BehaviorSubject } from 'rxjs';
import { DataService } from './services/data.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'info.zone';
  message: BehaviorSubject<any>;

  constructor(
    private msgService: MessagingService,
    // private pwaService: PwaService,
    public data: DataService,
    private gtmService: GoogleTagManagerService,
    private router: Router,
  ) {

  }

  ngOnInit() {
    this.msgService.requestPermission();
    this.msgService.receiveMessage();
    this.message = this.msgService.currentMessage;

    // push GTM data layer for every visited page
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }

}
