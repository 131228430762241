import { IZAuthSocialParent } from './auth.model';
import { IZCustomer } from './customer.model';
import { InfoZone, InfoZoneFactory } from './info-zone.model';
import firebase from 'firebase';
import { IZCategory } from './category.model';
import { v2TS } from '../services/toolz';
import { IZKid } from './kid.model';
import { IZBookmark } from './bookmark.model';
import { IZFeed } from './feed.model';

export enum IZZType {
    free = 10,
    private = 20,
    public = 30,
    bluetooth = 40,
    dummy = 50,
}

export interface IZZone extends InfoZone {
    izid: string;
    password?: string;
    ztype: IZZType;
    status: number;
    title: string;
    description?: string;
    refCategory?: string;
    category?: IZCategory;
    zmode?: number; // 0-nothing, 1-online, 2-oflline, 3-both
    link?: string;

    logo?: string;

    location?: string;
    plusCode?: string;
    geo?: {
        lat: number,
        lng: number,
    };
    geohash?: string,
    geohashi?: number,

    visibleFromd?: firebase.firestore.Timestamp | Date;
    visibleTilld?: firebase.firestore.Timestamp | Date;

    periodFromd?: firebase.firestore.Timestamp | Date;
    periodTilld?: firebase.firestore.Timestamp | Date;

    tags?: string[];
    color1?: string;

    // customer
    customer?: IZCustomer;
    refCustomer?: string;

    // members
    membersCount?: number;

    // ignores by save
    bookmark?: IZBookmark;
    feed?: IZFeed;
    handshakeRequest?: IZKid;
    notifiUser?: IZKid;
    checkinI?: IZKid;
    users?: IZAuthSocialParent[];

    // filters?: string[];
}

export class ZoneFactory extends InfoZoneFactory {
    static create(o: Partial<IZZone>): IZZone {
        return {
            ...super.create(o),
            izid: o.uid, // izid(),
            title: '',
            ztype: 10,
            status: 10,
            color1: 'f6f6f6', // '#e5faf2',
            refCustomer: '',
            tags: [],
            logo: '',
            link: '',
            zmode: 0,
            // membersCount: 0,
        };
    }

    static mapTo(o: IZZone): void {
        super.mapTo(o);
        delete o.filters;
        delete o.customer;
        delete o.category;
        delete o.bookmark;
        delete o.feed;
        delete o.handshakeRequest;
        delete o.notifiUser;
        delete o.checkinI;
        o.visibleFromd = v2TS(o.visibleFromd);
        o.visibleTilld = v2TS(o.visibleTilld);
        o.periodFromd = v2TS(o.periodFromd);
        o.periodTilld = v2TS(o.periodTilld);
    }
}




