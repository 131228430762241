<div class="iz-navigations">

  <div
    class="iz-navigation"
    (click)="navi('/user/gesamtliste')"
  >
    <div class="col1">
      <div class="title1">
        INFO.ZONE
      </div>
      <div class="title2">
        Suche und Check-In
      </div>
    </div>
    <div class="col2">
      <mat-icon class="icon">keyboard_arrow_right</mat-icon>
    </div>
  </div>

  <div
    class="iz-navigation"
    (click)="navi('/user/my-list')"
  >
    <div class="col1">
      <div class="title1">
         Zonen
      </div>
      <div class="title2">
        Listen und Informationen
      </div>
    </div>
    <div class="col2">
      <mat-icon class="icon">keyboard_arrow_right</mat-icon>
    </div>
  </div>

  <div
    class="iz-navigation"
    (click)="navi('/user/bookmark/users')"
  >
    <div class="col1">
      <div class="title1">
        Personen
      </div>
      <div class="title2">
        Highlights und Chat
      </div>
    </div>
    <div class="col2">
      <mat-icon class="icon">keyboard_arrow_right</mat-icon>
    </div>
  </div>



  <div
    class="iz-navigation"
    (click)="navi('/user/archiv-list')"
  >
    <div class="col1">
      <div class="title1">
        Archiv
      </div>
      <div class="title2">
        Vergangenes und Inaktives
      </div>
    </div>
    <div class="col2">
      <mat-icon class="icon">keyboard_arrow_right</mat-icon>
    </div>
  </div>


  <div
    class="iz-navigation"
    (click)="navi('/user/feed')"
  >
    <div class="col1">
      <div class="title1">
        Feed
      </div>
      <div class="title2">
        Neuigkeiten folgen
      </div>
    </div>
    <div class="col2">
      <mat-icon class="icon">keyboard_arrow_right</mat-icon>
    </div>
  </div>


  <div
    class="iz-navigation"
    (click)="navi('/user/profile')"
  >
    <div class="col1">
      <div class="title1">
        Profil
      </div>
      <div class="title2">
        Einstellungen
      </div>
    </div>
    <div class="col2">
      <mat-icon class="icon">keyboard_arrow_right</mat-icon>
    </div>
  </div>


  <div
    *ngIf="!auth?.userSocialParent"
    class="iz-navigation"
    (click)="logout()"
  >
    <div class="col1">
      <div class="title1">
        Logout
      </div>
      <div class="title2">
        Abmelden
      </div>
    </div>
    <div class="col2">
      <mat-icon class="icon">keyboard_arrow_right</mat-icon>
    </div>
  </div>

</div>
